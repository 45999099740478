import React from 'react';
import { horizontalStyleRight, inputStyle, bg, softColor } from "../styles";
import { Help } from './Help';

const emailpattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
const attributepattern = /^[a-z][\w\d-]*$/g;
const valuePattern = /^[^\{\}]*(((\{[@]?[*]?[a-z][\w\d\-]*([\.][a-z\*][\w\d\-]*)*)*\})*[^\{\}]*)*$/g;
const countryCodePattern = /^[A-Z][A-Z]$/g;
const currencyPattern = /^(SEK|NOK|EUR|GBP|USD|([\w][\w][\w]))$/g;
const namePattern = /^[\p{L}][\p{L}0-9 ]+$/ug;
const urlPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g;
const serverPattern = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g;
const serialPattern = /^[a-zA-Z0-9\-]+$/g;
const adminPattern = /^[a-zA-Z]*$/g;

const validators = {
  attribute : v => v.match(attributepattern),
  email : v => v.match(emailpattern),
  value : v => v.match(valuePattern),
  serial : v => v.match(serialPattern),
  number : v => (typeof(v)!=="string") || !(v.includes('-') || v.includes('e') || v.length===0),
  countrycode : v => v.match(countryCodePattern),
  currency : v => v.match(currencyPattern),
  name : v => v.match(namePattern),
  url : v => v.match(urlPattern), 
  server : v => v.match(serverPattern), 
  admin : v => v.match(adminPattern),
  orgcode : v => v.length>4,
  password : v => v.length>5,
  billingref: v => v.length>0,
  boolean: v => v==="true" || v==="false",
}

const modifiers = {
  countrycode : v => v.substring(0,2).toUpperCase(),
  currency : v => v.toUpperCase(),
  name : v => (v.length>0)?(v.substring(0,1).toUpperCase() + v.substring(1)) : v,
  attribute : v => (v.length>0)?(v.substring(0,1).toLowerCase() + v.substring(1)) : v,
  url : v => v.toLowerCase(),
  server : v => v.toLowerCase(),
  email : v => v.toLowerCase(),
  serial: v=>v,
  admin: v=>v.toUpperCase(),
  boolean : v => { v = v.toLowerCase(); if (v.startsWith('t') || v==="1") v = "true"; if (v.startsWith('f') || v==="0") v = "false"; return v; }
}

const typeMap = {
  attribute: 'text',
  value : 'text',
  countrycode : 'text',
  email : 'email',
  url : 'text',
  server : 'text',
  orgcode: 'text',
  boolean: 'text',
  serial: 'text',
  admin: 'text',
}

const typeCompletions = {
  boolean: ["true", "false"],
}

export const validate = (type, text) => validators.hasOwnProperty(type) ? validators[type](text?text:"") : true;
export const invalid = (type, text) => !validate(type, text);

export const Input = (props) => {
  const [valid, setValid] = React.useState(validate(props.type, props.value));
  const [text, setText] = React.useState(props.value ? props.value : "");
  const [listId, setListId] = React.useState("list-"+Math.random());
  const [key, setKey] = React.useState("input-"+Math.random());

  let completions = props.completions ? props.completions:[];
  if (completions.length == 0 && typeCompletions[props.type])
    completions = typeCompletions[props.type];

  React.useEffect(()=>{
    let timeout;
    if (props.complete) {
      timeout = setTimeout(() => {
          console.log('Input: requesting completions for "' + text + '"');
          props.complete(text);
      }, 500);
    }
    return () => timeout && clearTimeout(timeout);
  }, [text]);

  const handleChange = (e) => {
    setValid(validate(props.type, e.target.value));
    setText(e.target.value);
    props.onChange(e);
  }

  const handleOnBlur = (e) => {
    if (modifiers.hasOwnProperty(props.type)) {
      const modified = modifiers[props.type](e.target.value);
      if (modified !== text) {
        console.log("modifying from " + text + " to " +modified);
        handleChange({target:{value:modified}});
      }
    }
  }

  const typeName = typeMap.hasOwnProperty(props.type) ? typeMap[props.type] : props.type;

  return <div style={{padding:"0.4vmin"}}>
         <div style={{textAlign:"left", fontSize:"3vmin"}}>{props.placeholder}</div>
          <input style={{...inputStyle}} 
            key={key}
            value={text}
            list={listId}
            type={typeName}
            pattern={props.type==="number" ? "[0-9]*": undefined}
            disabled={props.disabled}
            onChange={e=>handleChange(e)}
            onBlur={e=>handleOnBlur(e)}
            maxLength={props.maxLength?props.maxLength:"127"}></input>
          <div style={{...horizontalStyleRight, }}>
            <div style={{textAlign:"right", fontStyle:"italic", margin:"0.3vmin", color:valid?softColor:"#ecb", fontSize:"3vmin"}}>{props.hint?props.hint:<br></br>}
            </div>
            {props.help && <Help text={props.help}></Help>}
          </div>
          { completions &&
          <datalist id={listId}>
            {completions.map((c)=>{return <option value={c}></option>})}
          </datalist> }
        </div>;
}

