
import React from 'react';
import { buttonStyle, smallestFontSize, widgetFontSize, buttonColor, buttonHighlightColor } from "../styles";

export const Button = (props) => {
    const [high, setHigh] = React.useState(false);
    return <button 
        style={{...buttonStyle, color:props.disabled?buttonStyle.color+"5":(high?buttonStyle.color:buttonStyle.color+"c"), fontSize:props.small?"2.4vmin":widgetFontSize, 
        margin:props.small?"0.1vmin":"1vmin", padding:props.small?"0.1vmin":"1vmin", fontWeight:props.small?300:600, minWidth:props.small?"15vmin":"20vmin",
        background:high?buttonHighlightColor:buttonColor}}
        disabled={props.disabled} onClick={props.onClick} onMouseDown={()=>{setHigh(true); return props.onMouseDown()}}
        onMouseEnter={()=>setHigh(true)} onMouseLeave={()=>setHigh(false)}>{props.text}</button>
}

export const MenuButton = (props) => {
    const [high, setHigh] = React.useState(false);
    return <div><button 
        style={{...buttonStyle, width:"100%", color:props.disabled?buttonStyle.color+"5":(high?buttonStyle.color:buttonStyle.color+"c"), fontSize:props.small?"2.4vmin":widgetFontSize, 
        margin:props.small?"0.1vmin":"0.2vmin", padding:props.small?"0.1vmin":"1vmin", fontWeight:props.small?300:600, textAlign:"left", border:0, borderRadius:0,
        borderTopLeftRadius:"2vmin", background:high?buttonHighlightColor:buttonColor}}
        disabled={props.disabled} onClick={props.onClick}
        onMouseEnter={()=>setHigh(true)} onMouseLeave={()=>setHigh(false)}>{props.text}</button></div>
}


export const CloseButton = (props) => {
    return <div 
        onClick={props.onClick}
        style={{position:"absolute", top:"2vmin",right:"2vmin",
                        background:"#eee", width:smallestFontSize, 
                        height:smallestFontSize, borderRadius:smallestFontSize}}></div>
}
