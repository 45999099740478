import React from 'react';
import { buttonStyle, tableStyle, smallestFontSize, inputStyle } from "../styles";
import { Input, invalid } from "../components/Input";
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';

const AddUser = (props) => {
  const key = props.clientkey;
  const create = props.user ? false : true; // If no user was supplised, we are to create a new one
  const [name, setName] = React.useState(props.user ? props.user.name : "");
  const [pass, setPass] = React.useState(props.user ? props.user.pass : "");
  const [pass2, setPass2] = React.useState(props.user ? props.user.pass : "");
  const [admin, setAdmin] = React.useState(props.user ? props.user.admin : "");
  const [submit, setSubmit] = React.useState(false);
  const [removeConfirm, setRemoveConfirm] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [needLogin, setNeedLogin] = React.useState(false);

  React.useEffect(()=>{
    let cancelled = false;
    if (!submit)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/adduser?client=" + encodeURIComponent(props.client) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&key=" + encodeURIComponent(key) + 
          "&name=" + encodeURIComponent(name) + 
          "&pass=" + encodeURIComponent(pass) + 
          "&admin=" + encodeURIComponent(admin) +
          "&create=" + encodeURIComponent(create ? "true" : "false");
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("create user result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError("Failed to submit user " + name + " - check the data or select another user name");
            setSubmit(false);
          }
          else {
            console.log(props.currentuser, name)
            if (props.currentuser===name && pass !== "********") {
              setNeedLogin(true);
              window.localStorage.removeItem('key');
            } else
              props.onDone("User " + name + " submitted.");
          }
        }
      } catch (err) {
        console.log("Failed user create", err);
        (!cancelled) && setError("Failed to create user " + name + ": " + err.message);
        (!cancelled) && setSubmit(false);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  // User deletion
  React.useEffect(()=>{
    let cancelled = false;
    if (removeConfirm !== 2)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/deleteuser?client=" + encodeURIComponent(props.client) + 
          "&key=" + encodeURIComponent(key) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&name=" + encodeURIComponent(name);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("remove user result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError(i18('failedDeleteUser'));
            setSubmit(false);
          }
          else 
            props.onDone("User " + name + " deleted.");
        }
      } catch (err) {
        console.log("Failed user delete", err);
        (!cancelled) && setError("Failed to delete user " + name + ": " + err.message);
        (!cancelled) && setRemoveConfirm(0);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [removeConfirm]);

  if (error)
    return <div>
      {error}
      <hr></hr>
      <Button onClick={()=>{setError(false); setRemoveConfirm(0);}} text={i18('back')}/>
    </div>;

  if (removeConfirm === 1) {
    return <div>{i18('deleteUser')} {name}?<hr/>
            <Button text={i18('cancel')} onClick={()=>setRemoveConfirm(0)}></Button>
            <Button text={i18('delete')} onClick={()=>setRemoveConfirm(2)}></Button></div>
  }

  if (needLogin) {
    return <div>{i18('changedOwnPassword')}
      <hr/>
      <Button onClick={()=>window.location.href=window.location.href} text={i18('login')}/>
    </div>
  }


  if (!submit && (removeConfirm===0)) {
    return <div>
      {create ? i18('add') : i18('edit')} {i18('user')}
      <hr/>
      <Input type="email" maxLength="44" hint={i18('usernameHint')} disabled={!create} placeholder={i18('username')} style={inputStyle} value={name} onChange={(e)=>setName(e.target.value)}/>
      <Input type="password" maxLength="44" hint={i18('passwordHint')} placeholder={i18('password')} style={inputStyle} value={pass} onChange={(e)=>setPass(e.target.value)}/>
      <Input type="password" maxLength="44" hint={i18('confirmPasswordHint')} placeholder={i18('confirmPassword')} style={inputStyle} value={pass2} onChange={(e)=>setPass2(e.target.value)}/>
      {/*
      <input maxLength="44" placeholder="Access pattern" style={inputStyle} value={access} onChange={(e)=>setAccess(e.target.value)}/>
      <br/> */}
      <Input type="admin" maxLength="20" placeholder={i18('access')} hint={i18('accessHint')} value={admin} onChange={(e)=>setAdmin(e.target.value)}/>
      <hr></hr>
      <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
      <Button onClick={()=>setRemoveConfirm(1)} text={i18('delete')}/>
      <Button disabled={(pass!==pass2)||invalid('password', pass)||invalid('email', name)||
                        (props.currentuser===name && !admin.includes("A"))} onClick={()=>setSubmit(true)} text={i18('submit')}/>
    </div>
  } else {
    if (removeConfirm===2)
      return "Deleting user...";
    return "Creating user...";
  }
}
  
export const Users = (props) => {
  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const [users, setUsers] = React.useState(null);
  const [addUser, setAddUser] = React.useState(null); // User name when adding a user
  const [editUser, setEditUser] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    let f = async () => {
      try {
        const call = api + "/users?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(key) + "&user=" + encodeURIComponent(props.user);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setUsers(data);
        }
      } catch (err) {
        console.log("Failed user retreival", err);
        (!cancelled) && props.onDone("Failed to load users.");
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [addUser, editUser]);

  if (!(key && client)) {
    props.onDone(i18('errorMissingInformation'));
    return "...";
  }

  if (addUser) 
    return <AddUser client={client} currentuser={user} clientkey={key} onDone={()=>setAddUser(false)}/>;

  if (editUser)
    return <AddUser client={client} currentuser={user} clientkey={key} user={editUser} onDone={()=>setEditUser(null)}/>

  if (!users)
    return <div>{i18('loading')}<hr></hr><Button onClick={()=>props.onDone(null)} text={i18('cancel')}></Button></div>

  return <div>
    {i18('usersForClient')} {client}
    <hr/>
    {users && <div><div style={{maxHeight:"60vh", overflow:"auto"}}><table style={{...tableStyle, borderSpacing:"1vmin", fontSize:smallestFontSize}}>
      <thead>
        <th>{i18('username')}</th>
        <th>{i18('password')}</th>
        <th>{i18('access')}</th>
        <th></th>
      </thead>
      <tbody>
        {users.map((u) => 
          <tr key={u.name}><td>{u.name}</td>
          <td>********</td>
          <td>{u.admin}</td>
          <td><button style={{...buttonStyle, borderRadius:"1vmin", margin:2, padding:3, fontSize:smallestFontSize}} onClick={()=>setEditUser(u)}>{i18('edit')}</button></td></tr>)}
      </tbody>
    </table></div><hr/></div>}
    <Button onClick={()=>{setAddUser(true)}} text={i18('add')}></Button>
    <Button onClick={()=>{props.onDone(null)}} text={i18('done')}></Button>
  </div>;
}
