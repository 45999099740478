import React from 'react';
import { tableStyle, smallestFontSize, horizontalStyle } from "../styles";
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';
  
export const AccessLog = (props) => {
  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const [accesses, setAccesses] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    let f = async () => {
      try {
        const call = api + "/accesslog?client=" + encodeURIComponent(props.client) 
                         + "&key=" + encodeURIComponent(key) 
                         + "&user=" + encodeURIComponent(props.user);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
            setAccesses(data);
        }
      } catch (err) {
        console.log("Failed data retreival", err);
        (!cancelled) && props.onDone("Failed to load access log data.");
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, []);

  if (!(key && client)) {
    props.onDone(i18('errorMissingInformation'));
    return "...";
  }

  if (!accesses)
    return <div>{i18('loading')}<hr/><Button onClick={()=>props.onDone(null)} text={i18('cancel')}></Button></div>

  return <div>
    <div style={horizontalStyle}>{i18('accessFor')} {client}</div>
    <hr/>
    {accesses && <div><div style={{maxHeight:"60vh", overflow:"auto"}}><table style={{...tableStyle, borderSpacing:"1vmin", fontSize:smallestFontSize}}>
      <thead>
        <th>{i18('username')}</th>
        <th>{i18('date')}</th>
        <th>{i18('action')}</th>
        <th></th>
      </thead>
      <tbody>
        {accesses.map((d) => 
          <tr>
          <td>{d.username}</td>
          <td>{new Date(d.timestamp).toLocaleString()}</td>
          <td>{d.action}</td>
          </tr>)}
      </tbody>
    </table></div><hr/></div>}
    <Button onClick={()=>{props.onDone(null)}} text={i18('done')}></Button>
  </div>;
}
