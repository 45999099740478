import React from 'react';
import { Button } from "../components/Button";
import { i18 } from '../i18n/i18';
import { popupStyle, smallestFontSize, coverStyle, softColor } from '../styles';

export const TermsAndConditions = (props) => {
  const [show, setShow] = React.useState(false);

  if (show) {
    return <div style={coverStyle} onClick={()=>setShow(false)} >
      <div style={{...popupStyle, position:'fixed', width:"80vw", left:"10vw", top:"5vh", height:"90vh"}}><br/>Terms and Conditions, Privacy Policy
        <hr></hr>
        <small>
        {i18('termsIntroduction')}
        <br/><br/>
        <a href="qrlio-terms-and-conditions-v3.pdf" target="tac">Qrlio.com Terms and Conditions</a> 
        <br/>
        <a href="qrlio-privacy-policy-v3.pdf" target="pp">Qrlio.com Privacy Policy</a> 
        </small>
        <hr></hr>
        <Button text={i18('back')}/>
        </div>
      </div>
  }

  const link = <div onClick={()=>setShow(true)}>Terms &amp; Conditions, Privacy Policy</div>;
  if (props.short)
    return link;

  return <div onClick={()=>setShow(true)} style={{color:softColor, fontSize:smallestFontSize, fontWeight:600}}>
    <br/>
    {i18('byLoggingInYouAgree')}
    <br/><div style={{textDecoration:'underline'}}>{link}</div></div>;
}
