import React from 'react';
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';
import { horizontalStyle, coverStyle, popupStyle, } from '../styles';


export const SupplierNodeSelector = (props) => {
  const {supplierNode, setSupplierNode, onDone, client, user, clientkey} = props;
  const [nodes, setNodes] = React.useState(null);
  const [nodeNames, setNodeNames] = React.useState([]);

  React.useEffect(()=>{
    let cancelled = false;
    if (Array.isArray(nodes))
      return ()=>cancelled=true;
    const f = async () => {
      const call = api + '/listSupplierNodes?client=' + encodeURIComponent(client) + 
        "&user=" + encodeURIComponent(user) + 
        "&key=" + encodeURIComponent(clientkey) +
        "&match=" + encodeURIComponent(supplierNode);
      console.log("Calling " + call);
      try {
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setNodes(data);
          let names = [];
          data.map((n)=>names.push(n.name));
          setNodeNames(names);
          if (data.length === 1)
            setSupplierNode(data[0].name);
        }
      } catch (err) {
        (!cancelled) && setNodes([]);
      }
    };
    f();
    return ()=>cancelled=true;
  }, [nodes]);

  const MAX_NODES = 100;

  const isDisabled = () => { 
    console.log(supplierNode, nodeNames);
    if (Array.isArray(nodeNames)) {
      for (let i = 0; i < nodeNames.length; ++i) {
        if (nodeNames[i] === supplierNode)
          return false;
      }
    }
    return true;
  }

  const disabled = isDisabled();
  console.log(disabled);

  return <div style={horizontalStyle}>
    <Input placeholder={i18('supplierNode')} 
        hint={i18('supplierNodeHint')} value={supplierNode} 
          onChange={e=>{
            setSupplierNode(e.target.value);
          }} completions={nodeNames} complete={()=>setNodes(null)}/>
          <Button text={i18('supplierNodeSelect')} onClick={()=>onDone()} disabled={disabled}/>
    </div>
}
