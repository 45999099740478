import {en} from './en';
import {sv} from './sv';

var userLang = navigator.language || navigator.userLanguage; 

const languages = {
  en, 
  sv,
};

const validateLanguage = (language) => {
  // Reference language: English
  if (!languages[language]) {
    console.log("Error: " + language + " is not implemented");
    return;
  }

  // Check that all keys exist in English
  Object.keys(languages[language]).map(k => {
    if (!languages.en[k])
      console.log("Language " + language + " has key " + k + " which is missing in English");
  });

  // Check that all strings in English exist in the selected language
  Object.keys(languages.en).map(k => {
    if (!languages[language][k])
      console.log("Language " + language + " misses key " + k + " which is defined in English");
  });

}

let languageSelect = userLang.toLowerCase().substr(0,2);
console.log(languageSelect);
const language = languages.hasOwnProperty(languageSelect) ? languages[languageSelect] : languages.en;
validateLanguage(languageSelect);

export const i18 = (key) => {
  const translated = languages[languageSelect][key] ? languages[languageSelect][key] : (languages.en[key]?languages.en[key]:key);
  // console.log("Translate:" + key + " translates to " + translated);
  return translated;
}

export const i18ChangeLanguage = () => {
  let index = 0;
  let languageKeys = Object.keys(languages);
  languageKeys.map((k,i) => {if (k === languageSelect) index = i;});
  index++;
  if (index >= languageKeys.length)
    index = 0;
  languageSelect = languageKeys[index];
}

console.log("Selected language: ", i18('language'));
