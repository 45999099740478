import React from 'react';
import {buttonStyle} from '../styles';
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { SupplierNodeSelector } from '../components/SupplierNodeSelector';
import { api } from "../App"
import { i18 } from '../i18n/i18';
import { isImage, ImageView} from './Checkin';

export const Register = (props) => {
  const getDefaults = (values) => {
  let result = {};
    if (values)
      Object.keys(values).map(k => {result[k]=values[k].default; return 0;});
    return result;
  }
  const profiles = props.profiles;
  const profileInitial = props.profile ? parseInt(props.profile) : null;

  const getProfileCount = () => {
    if (!Array.isArray(profiles))
      return 0;
    let count = 0;
    for (let i = 0; i < profiles.length; ++i)
      if (!profiles[i].hidden)
        count++;
    return count;
  }

  const getFirstProfileIndex = () => {
    if (!Array.isArray(profiles))
      return 0;
    for (let i = 0; i < profiles.length; ++i)
      if (!profiles[i].hidden)
        return i;
    return 0;
  }

  const haveSingleProfile = () => 1 === getProfileCount();

  const initialProfileIndex = Number.isInteger(profileInitial) ? profileInitial : (haveSingleProfile() ? getFirstProfileIndex() : null);
  const [serial, setSerial] = React.useState(props.serial ? props.serial : NaN);
  let [data, setData] = React.useState(props.data ? props.data : ((initialProfileIndex !== null) ? getDefaults(profiles[initialProfileIndex].values) : {}));
  let [positioning, setPositioning] = React.useState(false);
  let [profileIndex, setProfileIndex] = React.useState(initialProfileIndex);
  let [supplierNode, setSupplierNode] = React.useState("");
  let [supplierNodeSelected, setSupplierNodeSelected] = React.useState(false);
  let [values, setValues] = React.useState(props.values ? props.values : ((initialProfileIndex !== null) ? profiles[initialProfileIndex].values : {}));
  const validProfileIndex = profiles && (profileIndex!==null) && (profileIndex!==undefined) && (profiles.length > profileIndex);
  const requireSupplierNode = validProfileIndex && (profiles[profileIndex].useSupplierNode!==0);
  let [submit, setSubmit] = React.useState((!requireSupplierNode) && (props.autosubmit ? true : false));
  console.log("Register: ", props.serial, profileIndex, requireSupplierNode, props.autosubmit, "suppliernode: " + supplierNode);

  let hidename = props.hidename;
  if (requireSupplierNode)
    hidename = false;

  const checkValues = () => {
    return true;
  }

  console.log("Register: props=", props, " data=", data, positioning, profileIndex, supplierNode, values, serial, submit);
  React.useEffect(() => {
    let cancelled = false;
    if (!props.clientkey) {
      props.onDone("No key provided to Register");
      return;
    }
    if (!submit) {
      return;
    }
    if (!validProfileIndex) {
      setSubmit(false);
      return;
    }
    if (requireSupplierNode && supplierNode.length ===0) {
      setSubmit(false);
      return;
    }
    const closeForm = (err, credits) => {
      if(cancelled)
        return;
      if (err)
        props.onDone(err);
      else {
        props.onDone(null, credits, profileIndex, values, data);
      }
    }
    const f = async () => {
      try {
        const call = api + '/register?name=' + encodeURIComponent(serial) + 
        "&client=" + encodeURI(props.client) + 
        "&key=" + encodeURIComponent(props.clientkey) + 
        "&profile=" + encodeURIComponent(profiles[profileIndex].name) + 
        "&value=" + encodeURIComponent(JSON.stringify(data)) + 
        "&user=" + encodeURIComponent(props.user) +
        "&supplierNode=" + encodeURIComponent(supplierNode) + 
        "&hash=" + encodeURIComponent(props.hash);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const {created, credits, hash} = await response.json();
        console.log("created:", created, hash);
        if (created) {
          (!cancelled) && setPositioning(true);
          navigator.geolocation.getCurrentPosition((position) => {
            console.log(position);
            if (position && position.coords && position.coords.latitude && position.coords.longitude) {
              try {
                const call = api + '/updateposition?name=' + encodeURI(serial) + "&lat=" + position.coords.latitude + "&lng=" + position.coords.longitude  + "&client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(props.clientkey) + "&user="+encodeURIComponent(props.user);
                const ff = async () => {
                  await fetch(call);
                  closeForm(null, credits);
                  (!cancelled) && props.onDone(null, credits, profileIndex, values);
                }
                ff();
              } catch (err) {
                closeForm(null, credits);
              }
            } 
          },
          error => {
            console.error("positioning: Error code = " + error.code + " - " + error.message);
            closeForm(null, credits);
          });
        } else {
          closeForm(i18('errorFailedRegister'), credits);
        }
      } catch (err) {
        closeForm(i18('errorFailedRegister'));
      }
    }
    f();
    return () => {cancelled = true}
  }, [serial, submit, validProfileIndex, profileIndex, props.profiles]);

  const profileSelector = <select value={(profileIndex === null || profileIndex===undefined) ? getFirstProfileIndex() : profileIndex} onChange={(e) => {
      console.log(e.target.value, "Profile:", profiles[e.target.value].name); 
      setValues(profiles[e.target.value].values);
      setData(getDefaults(profiles[e.target.value].values));
      setProfileIndex(e.target.value);
    }} style={buttonStyle} name="profiles" id="profiles">
      {profiles.map((p,n) => p.hidden ? null : <option key={n} value={n}>{p.name}</option>)}
    </select>;

  if ((!validProfileIndex) || (requireSupplierNode&&supplierNode==="") || !(positioning || submit)) {
    if (validProfileIndex) { // A valid profile is selected
      console.log("values: ", values, typeof(values));
      console.log("profiles: ", profiles, typeof(profiles));
      const valueCount = values ? Object.keys(values) : 0;
      return <div>
        {(!hidename) && profiles[profileIndex].name+ " " + serial}
        {(!hidename) && <hr/>}
        {requireSupplierNode && <div>
          {(!supplierNodeSelected) && <SupplierNodeSelector 
              supplierNode={supplierNode} setSupplierNode={setSupplierNode} client={props.client} clientkey={props.clientkey} 
              user={props.user} onDone={()=>setSupplierNodeSelected(true)} />}
          {supplierNodeSelected && <div>{i18('supplierNode')} : {supplierNode}</div>}
          <hr></hr>
          </div>}
        { values && Object.keys(values).map(k => {
          if (!values[k].editable)
            return null;
          if (data[k] && typeof(isImage(data[k])) === "string") {
            // This is a place for an image, request one
            return <ImageView hash={isImage(data[k])} client={props.client} user={props.user} clientkey={props.clientkey} attribute={values[k].name} onUploaded={(hash)=>{
                let newData = {...data};
                newData[k]="img:" + hash;
                setData(newData);
                console.log("data with image:", newData); }}/>
          }
          return <Input type="value" placeholder={values[k].name} hint={k} disabled={!values[k].editable} value={data[k]} onChange={(e)=>{
              let newData = {...data};
              newData[k]=e.target.value;
              setData(newData);
              console.log("data:", newData); }}/>
        }) }
        {valueCount>0 &&<hr/>}
        { <Button text={i18('back')} onClick={()=>{setProfileIndex(null);setSupplierNodeSelected(null);}}></Button>}
        {checkValues() && 
          <Button text={i18('registerSerial')} onClick={()=>setSubmit(true)} disabled={profiles[profileIndex].useSupplierNode && (!supplierNodeSelected)}></Button>}
      </div>
    } else { // Select profile
      return <div>
      {i18('selectProfile')}
      <hr/>
      {profileSelector}
      <hr/>
      <Button text={i18('back')} onClick={()=>props.onDone(i18('errorNoProfileSelected'))}></Button>
      <Button text={i18('select')} onClick={()=>{
        console.log("Selected Profile:", profileIndex);
        setProfileIndex(profileIndex ? profileIndex: getFirstProfileIndex());
        setValues(profiles[profileIndex ? profileIndex : getFirstProfileIndex()].values)
        setData(getDefaults(profiles[profileIndex ? profileIndex : getFirstProfileIndex()].values))
      }}></Button>
      </div>
    }
  } else /* positioning or registerring */ {
    if ((!validProfileIndex) || (requireSupplierNode&&supplierNode==="")) {
      setPositioning(false);
      setSubmit(false);
      return <div>...</div>;
    }
    return <div>
      {(positioning ? i18('positioning') : i18('registering')) + " " + profiles[profileIndex].name + " " + serial + "..."}
      {positioning && <hr></hr>}
      {positioning && <Button text={i18('cancel')} onClick={()=>props.onDone()}/>}
    </div>;
  }
}

export const MultiRegister = (props) => {
  const first = props.first;
  const last = props.last;
  const credits = props.credits;
  const setCredits = props.setCredits;
  const [current, setCurrent] = React.useState(props.first);
  const [profile, setProfile] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [data, setData] = React.useState(null);

  console.log("Current credits: ", credits);

  if (parseInt(current) > parseInt(last)) {
    console.log("Multiregister completed")
    return <div>{i18('created')} {first}-{last}
      <hr></hr>
      {i18('creditsLeft')}: {credits}
      <hr></hr>
      <Button onClick={()=>props.onDone(null, credits)} text="Done"/>
    </div>;
  }

  return <div>
    {parseInt(first) === parseInt(current) && <div>Select settings for serials {first} - {last}</div>}
    {parseInt(first) !== parseInt(current) && <div>Serial {current} in serials {first} - {last}</div>}
    <hr></hr>
    <Register key={current} client={props.client} clientkey={props.clientkey} user={props.user} profiles={props.profiles} serial={current} hidename={true}
              profile={profile} values={values} data={data} credits={props.credits} autosubmit={first===current} onDone={(err, credits, profile, values, data) => {
      console.log("Register done: serial: ", current, "err creds prof value: ", err, credits, profile, values, data);
      if (err)
        props.onDone(err, credits);
      else {
        setValues(values);
        setData(data);
        setCredits(credits);
        setProfile(profile);
        setCurrent(parseInt(current)+1);
      }
    }} />
    </div>;
}
  
export const Qrl = (props) => {
  const [qrl, setQrl] = React.useState(null);
  const [fail, setFail] = React.useState(false);

  console.log("Qrl: ", props.client, props.clientkey, props.serial);

  const height = props.height;
  const myloc = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
  const app = myloc;
  const call = api + '/qrl?name=' + encodeURIComponent(props.serial) +"&app=" + encodeURIComponent(app) + "&client=" + encodeURIComponent(props.client) + "&user=" + encodeURIComponent(props.user) + "&key=" + encodeURIComponent(props.clientkey);
  React.useEffect( () => {
    let cancelled = false;
    const f = async () => {
      console.log("Calling " + call);
      try {
        if (!props.clientkey || !props.client) {
          props.onDone("Qrl: Missing client key or client");
          return;
        }
        const response = await fetch(call);
        console.log("returned:", response);
        const data = await response.json();
        console.log("qrl:", data);
        if (!cancelled)
          setQrl(data);
      } catch (err) {
        setFail(true);
        props.onDone(i18('failedQRpt1') + " " + props.serial + " " + i18('failedQRpt2'));
      }
    }
    f();
    return ()=>{cancelled=true; };
  }, [props.serial]);

  if (props.silent)
    return qrl ? (<div style={{fontSize:"9pt", flexDirection:"column", display:"flex" }}><img height={props.height ? props.height: "250"} src={qrl.qr} alt={qrl.qrl}/>
        <div style={{fontSize:(parseInt(props.height)/14)+"pt", marginTop:`${-parseInt(props.height)/15}`+"pt"}}>{props.serial}</div></div>) : (
        <div style={{fontSize:(parseInt(props.height)/14)+"pt", height:props.height?props.height+"pt":"250pt", background:fail?"#fff":"#ccc", width:props.height?props.height+"pt":"250pt", textAlign:"center"}}>{fail ?"":props.serial}</div>);

  return <div>
      {i18('QRCodeFor')} {props.serial}
      <hr></hr>
      {qrl ? <a href={qrl.qrl}><img height={height ? height: "250"} src={qrl.qr} alt={qrl.qrl}/></a> : <div style={{height:props.height?props.height:"250"}}>Generating...</div>}
      <hr></hr>
      {qrl && qrl.qrl && <Button onClick={() => {navigator.clipboard.writeText(qrl.qrl)}} text={i18('copyURL')}/>}
      <Button text={i18('back')} onClick={()=>{props.onDone(null)}}/>
      </div>;
}

