import React from 'react';
import { horizontalStyle, buttonStyle, tableStyle, smallestFontSize, inputStyle } from "../styles";
import { Input, invalid } from "../components/Input";
import { Button } from "../components/Button";
import { api } from "../App"
import { Values} from "./Values";
import { i18 } from '../i18n/i18';
import { Help } from '../components/Help';

const AddProfile = (props) => {
  const key = props.clientkey;
  const create = props.profile ? false : true; // If no user was supplised, we are to create a new one
  const [name, setName] = React.useState(props.profile ? props.profile.name : "");
  const [note, setNote] = React.useState(props.profile ? props.profile.note : "");
  const [useSupplierNode, setUseSupplierNode] = React.useState(props.profile ? (props.profile.useSupplierNode ? "true": "false") : "false");
  const [requestPublicPosition, setRequestPublicPosition] = React.useState(props.profile ? (props.profile.requestPublicPosition ? "true": "false") : "false");

  const [submit, setSubmit] = React.useState(false);
  const [removeConfirm, setRemoveConfirm] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [editValues, setEditValues] = React.useState(false); // If true we show a value editor

  // Add/edit
  React.useEffect(()=>{
    let cancelled = false;
    if (!submit)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/addprofile?client=" + encodeURIComponent(props.client) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&key=" + encodeURIComponent(key) + 
          "&name=" + encodeURIComponent(name) + 
          "&note=" + encodeURIComponent(note) + 
          "&useSupplierNode=" + encodeURIComponent(useSupplierNode==="true" ? "true" : "false") +
          "&requestPublicPosition=" + encodeURIComponent(requestPublicPosition==="true" ? "true" : "false") +
          "&create=" + encodeURIComponent(create ? "true" : "false");
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("create profile result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError("Failed to submit profile " + name + " - select another profile name");
            setEditValues(false);
            setSubmit(false);
          }
          else {
            setSubmit(false);
          }
        }
      } catch (err) {
        console.log("Failed profile create", err);
        (!cancelled) && setError("Failed to create profile " + name + ": " + err.message);
        (!cancelled) && setSubmit(false);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  // Profile deletion
  React.useEffect(()=>{
    let cancelled = false;
    if (removeConfirm !== 2)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/deleteprofile?client=" + encodeURIComponent(props.client) + 
          "&key=" + encodeURIComponent(key) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&name=" + encodeURIComponent(name);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("remove profile result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError(i18('errorCouldNotDeleteProfile'));
            setSubmit(false);
          }
          else 
            props.onDone("Profile " + name + " deleted.");
        }
      } catch (err) {
        console.log("Failed profile delete", err);
        (!cancelled) && setError(i18('errorCouldNotDeleteProfile'));
        (!cancelled) && setRemoveConfirm(0);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [removeConfirm]);

  if (error)
    return <div>
      {error}
      <hr></hr>
      <Button onClick={()=>{setError(false); setRemoveConfirm(0);}} text={i18('back')}/>
    </div>;

  if (removeConfirm === 1) {
    return <div>{i18('delete')}  {name}?<hr/>
          {i18('deleteProfileNotice')}
          <hr/>
            <Button text={i18('cancel')} onClick={()=>setRemoveConfirm(0)}></Button>
            <Button text={i18('delete')} onClick={()=>setRemoveConfirm(2)}></Button></div>
  }

  if (submit)
    return <div>{i18('processing')}</div>

  if (editValues)
    return <Values client={props.client} user={props.currentuser} clientkey={key} profile={name} onDone={()=>props.onDone()}/>;

  if (!submit && (removeConfirm===0)) {
    return <div><div style={horizontalStyle}>
      {create ? i18('add') : i18('edit')} {i18('profile')} <Help text="helpProfiles"/>
      </div>
      <hr/>
      <Input key={0} maxLength="44" type="name" hint={i18('nameHint')} disabled={!create} placeholder={i18('name')} style={inputStyle} value={name} onChange={(e)=>setName(e.target.value)}/>
      <Input key={1} maxLength="44" placeholder={i18('note')} hint={i18('profileNoteHint')} value={note} onChange={(e)=>setNote(e.target.value)}/>
      { props.allowSupplierNodes && <Input key={2} type="boolean" maxLength="8" help="helpUseSupplierNode" placeholder={i18('useSupplierNode')} disabled={!create} hint={i18('useSupplierNodeHint')} value={useSupplierNode} onChange={(e)=>setUseSupplierNode(e.target.value)}/>}
      <Input key={3} type="boolean" maxLength="8" help="helpRequestPublicPosition" placeholder={i18('requestPublicPosition')} hint={i18('requestPublicPositionHint')} value={requestPublicPosition} onChange={(e)=>setRequestPublicPosition(e.target.value)}/>
      <hr></hr>
      <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
      <Button onClick={()=>setRemoveConfirm(1)} text={i18('delete')}/>
      <Button onClick={()=>{setEditValues(true); setSubmit(true)}} disabled={invalid('name', name) || invalid('boolean', useSupplierNode)} text={i18('next')}/>
    </div>
  } else {
    if (removeConfirm===2)
      return "Deleting profile...";
    return "Creating profile...";
  }
}
  
export const Profiles = (props) => {
  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const [profiles, setProfiles] = React.useState(null);
  const [addProfile, setAddProfile] = React.useState(null);
  const [editProfile, setEditProfile] = React.useState(null);
  const [allowSupplierNodes, setAllowSupplierNodes] = React.useState(false);

  React.useEffect(()=>{
    let cancelled = false;
    let f = async () => {
      try {
        const call = api + "/profiles?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(key) + "&user=" + encodeURIComponent(props.user);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setProfiles(data.profiles);
          setAllowSupplierNodes(data.allowSupplierNodes);
        }
      } catch (err) {
        console.log("Failed profile retreival", err);
        (!cancelled) && props.onDone("Failed to load profiles.");
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [addProfile, editProfile]);

  if (!(key && client)) {
    props.onDone(i18('errorMissingInformation'));
    return "...";
  }

  if (addProfile) 
    return <AddProfile client={client} currentuser={user} clientkey={key} allowSupplierNodes={allowSupplierNodes} onDone={()=>setAddProfile(false)}/>;

  if (editProfile)
    return <AddProfile client={client} currentuser={user} clientkey={key} allowSupplierNodes={allowSupplierNodes} profile={editProfile} onDone={()=>setEditProfile(null)}/>

  if (!profiles)
    return <div>{i18('loading')}<hr/><Button onClick={()=>props.onDone(null)} text={i18('cancel')}></Button></div>

  return <div>
   <div style={horizontalStyle}>{i18('profilesFor')} {client}<Help text="helpProfiles"/></div>
    <hr/>
    {profiles && <div><div style={{maxHeight:"60vh", overflow:"auto"}}>
      <table style={{...tableStyle, borderSpacing:"1vmin", fontSize:smallestFontSize}}>
      <thead>
        <th>{i18('name')}</th>
        <th>{i18('note')}</th>
        <th></th>
      </thead>
      <tbody>
        {profiles.map((p) => p.hidden ? null :
          <tr key={p.name} id={p.name}>
          <td>{p.name}</td>
          <td>{p.note}</td>
          <td><button style={{...buttonStyle, borderRadius:"1vmin", margin:2, padding:3, fontSize:smallestFontSize}} onClick={()=>setEditProfile(p)}>{i18('edit')}</button></td></tr>)}
      </tbody>
    </table></div><hr/></div>}
    <Button onClick={()=>{setAddProfile(true)}} text={i18('add')}></Button>
    <Button onClick={()=>{props.onDone(null, profiles)}} text={i18('done')}></Button>
  </div>;
}
