import React from 'react';
import { buttonStyle, tableStyle, smallestFontSize, inputStyle,horizontalStyle } from "../styles";
import { Input, invalid, validate } from "../components/Input";
import { Button } from "../components/Button";
import { Help } from '../components/Help';
import { api } from "../App"
import { i18 } from '../i18n/i18';

const AddValue = (props) => {
  const key = props.clientkey;
  const create = props.value ? false : true; // If no user was supplised, we are to create a new one
  const [attribute, setAttribute] = React.useState(props.value ? props.value.attribute : "");
  const [name, setName] = React.useState(props.value ? props.value.name : "");
  const [deft, setDeft] = React.useState(props.value ? props.value.default : "");
  const [publ, setPubl] = React.useState(props.value ? (props.value.public?"true":"false") : "false");
  const [admin, setAdmin] = React.useState(props.value ? props.value.admin : "ARL");
  const [editable, setEditable] = React.useState(props.value ? (props.value.editable?"true":"false") : "false");

  const [submit, setSubmit] = React.useState(false);
  const [removeConfirm, setRemoveConfirm] = React.useState(0);
  const [error, setError] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    if (!submit)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/addvalue?client=" + encodeURIComponent(props.client) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&key=" + encodeURIComponent(key) + 
          "&profile=" + encodeURIComponent(props.profile) + 
          "&attribute=" + encodeURIComponent(attribute) +
          "&name=" + encodeURIComponent(name) + 
          "&deft=" + encodeURIComponent(deft) + 
          "&public=" + encodeURIComponent(publ === "true" ? "true" : "false") + 
          "&admin=" + encodeURIComponent(admin) + 
          "&editable=" + encodeURIComponent(editable === "true" ? "true" : "false") + 
          "&create=" + encodeURIComponent(create ? "true" : "false");
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("create value result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError("Failed to submit value " + name + " - select another attribute name?");
            setSubmit(false);
          }
          else 
            props.onDone("Value " + name + " submitted.");
        }
      } catch (err) {
        console.log("Failed value create", err);
        (!cancelled) && setError("Failed to create value " + name + ": " + err.message);
        (!cancelled) && setSubmit(false);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  // User deletion
  React.useEffect(()=>{
    let cancelled = false;
    if (removeConfirm !== 2)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/deletevalue?client=" + encodeURIComponent(props.client) + 
          "&key=" + encodeURIComponent(key) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&profile=" + encodeURIComponent(props.profile) +
          "&name=" + encodeURIComponent(name);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("remove value result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError("Failed to delete value " + name);
            setSubmit(false);
          }
          else 
            props.onDone("Value " + name + " deleted.");
        }
      } catch (err) {
        console.log("Failed value delete", err);
        (!cancelled) && setError("Failed to delete value " + name + ": " + err.message);
        (!cancelled) && setRemoveConfirm(0);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [removeConfirm]);

  if (error)
    return <div>
      {error}
      <hr></hr>
      <Button onClick={()=>{setError(false); setRemoveConfirm(0);}} text={i18('back')}/>
    </div>;

  if (removeConfirm === 1) {
    return <div>{i18('delete')} {name}?<hr/>
            <Button text={i18('cancel')} onClick={()=>setRemoveConfirm(0)}></Button>
            <Button text={i18('delete')} onClick={()=>setRemoveConfirm(2)}></Button></div>
  }

  if (!submit && (removeConfirm===0)) {
    return <div>
      <div style={horizontalStyle}>
      {props.profile} - {create ? i18('add') : i18('edit')} {i18('value')}
      <Help text="helpValue"/>
      </div>
      <hr/>
      <Input maxLength="44" type="attribute" placeholder={i18('attribute')} hint={i18('nameHint')} disabled={!create} style={inputStyle} value={attribute} onChange={(e)=>setAttribute(e.target.value)}/>
      <Input maxLength="44" type="name" placeholder={i18('label')} hint={i18('labelHint')} value={name} onChange={(e)=>setName(e.target.value)}/>
      <Input maxLength="127" type="value" help="helpDeft" placeholder={i18('defaultValue')} hint={i18('defaultHint')} value={deft} onChange={(e)=>setDeft(e.target.value)}/>
      <Input maxLength="10" type="boolean" placeholder={i18('public')} hint={i18('publicHint')} value={publ} onChange={(e)=>setPubl(e.target.value)}/>
      <Input maxLength="20" type="access" placeholder={i18('access')} hint={i18('accessHint')} value={admin} onChange={(e)=>setAdmin(e.target.value)}/>
      <Input maxLength="10" type="boolean" placeholder={i18('editable')} hint={i18('editableHint')} value={editable} onChange={(e)=>setEditable(e.target.value)}/>
      <hr></hr>
      <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
      <Button onClick={()=>setRemoveConfirm(1)} text={i18('delete')}/>
      <Button onClick={()=>setSubmit(true)} disabled={invalid('boolean', publ) || invalid('boolean', editable) 
                    || invalid('name', name) || invalid('attribute', attribute) || invalid("value", deft)} text={i18('submit')}/>
    </div>
  } else {
    if (removeConfirm===2)
      return "Deleting value...";
    return "Creating value...";
  }
}

export const Values = (props) => {

  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const profile = props.profile;
  const [values, setValues] = React.useState(null);
  const [addValue, setAddValue] = React.useState(null);
  const [editValue, setEditValue] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    let f = async () => {
      try {
        const call = api + "/values?client=" + encodeURIComponent(props.client) 
          + "&key=" + encodeURIComponent(key) 
          + "&user=" + encodeURIComponent(props.user) 
          + "&profile=" + encodeURIComponent(profile);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setValues(data);
        }
      } catch (err) {
        console.log("Failed values retreival", err);
        (!cancelled) && props.onDone("Failed to load values.");
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [addValue, editValue]);

  if (!(key && client && profile)) {
    props.onDone("Missing client, key, or profile information");
    return "...";
  }

  if (addValue) 
    return <AddValue client={client} currentuser={user} clientkey={key} profile={profile} onDone={()=>setAddValue(false)}/>;

  if (editValue)
    return <AddValue client={client} currentuser={user} clientkey={key} profile={profile} value={editValue} onDone={()=>setEditValue(null)}/>

  if (!values)
    return <div>{i18('loading')}<hr/>{props.onDone && <Button onClick={()=>props.onDone(null)} text={i18('cancel')}></Button>}</div>

  return <div>
    <div style={horizontalStyle}>
    {i18('valuesFor')} {profile} <Help text="helpValue"/>
    </div>
    <hr/>
    {values && <div><div style={{maxHeight:"60vh", overflow:"auto"}}><table style={{...tableStyle, borderSpacing:"1vmin", fontSize:smallestFontSize}}>
      <thead>
        <th>{i18('attribute')}</th>
        <th>{i18('label')}</th>
        <th>{i18('defaultValue')}</th>
        <th>{i18('public')}</th>
        <th>{i18('access')}</th>
        <th>{i18('editable')}</th>
      </thead>
      <tbody>
        {values.map((v) => 
          <tr key={v.attribute}>
          <td>{v.attribute}</td>
          <td>{v.name}</td>
          <td>{v.default}</td>
          <td>{v.public ? "true" : "false"}</td>
          <td>{v.admin}</td>
          <td>{v.editable ? "true" : "false"}</td>
          <td><button style={{...buttonStyle, borderRadius:"1vmin", margin:2, padding:3, fontSize:smallestFontSize}} onClick={()=>setEditValue(v)}>{i18('edit')}</button></td></tr>)}
      </tbody>
    </table></div><hr/></div>}
    <Button onClick={()=>{setAddValue(true)}} text={i18('add')}></Button>
    {props.onDone && <Button onClick={()=>{props.onDone(null)}} text={i18('done')}></Button>}
  </div>;
}
