// Copyright (C) 2022, Rutio AB, All rights reserved

import React from 'react';
import { Button } from "../components/Button";
import { Input } from '../components/Input';
import { Positron } from '../components/Positron';
import { Help } from '../components/Help';
import { api } from "../App"
import { pageStyle, buttonStyle, popupBackground, tableStyle, buttonHighlightColor, smallestFontSize, smallestText, buttonColor, softColor, bg, horizontalStyle, fg} from '../styles';
import { i18 } from '../i18n/i18';
import { ShowLog } from './ShowLog';
import { TimeSpace } from '../components/TimeSpace';

const isUri = (str) => {
  return (typeof(str)==="string") && 
    (str.startsWith("http") ||
     str.startsWith("tel:") ||
     str.startsWith("mailto:"));
}

const isEdit = (str) => {
  if (typeof(str)!=="string")
    return false;
  if (!str.startsWith("edit:"))
    return false;
  return str.substring(5);
}

const isMultiLineEdit = (str) => {
  if (typeof(str)!=="string")
    return false;
  if (!str.startsWith("text:"))
    return false;
  return str.substring(5);
}

export const isImage = (str) => {
  if (typeof(str)!=="string")
    return false;
  if (!str.startsWith("img:"))
    return false;
  return str.substring(4);
}

export const ImageView = ({hash, client, user, clientkey, attribute, onUploaded}) => {
  const [zoom, setZoom] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploadedHash, setUploadedHash] = React.useState(null);

  const admin = window.localStorage.getItem("admin");
  const mayUpload = admin && (admin.includes("U") || admin.includes("R"));

  const uploadImage = async (file) => {
    if (!file)
      return;
    const call = api + "/postimage?client=" + encodeURIComponent(client) + 
              "&user=" + encodeURIComponent(user) + 
              "&key=" + encodeURIComponent(clientkey);
    console.log("Calling " + call);

    const formData = new FormData();
    formData.append("image", file, file.name);
    console.log(formData);

    const response = await fetch(call, {
      method: "POST",
      body: formData, 
    });
    const data = await response.json();
    console.log("Got data after post: ", data);
    setUploadedHash(data.hash);
    onUploaded(data.hash);
  }

  let image = null;
  if (selectedFile) {
    image = <img src={URL.createObjectURL(selectedFile)} width={zoom ? "100%" : "40%"} onClick={()=>setZoom(!zoom)}/>
  } else {
    if (!hash || hash==="") {
      image = <div style={{smallestFontSize}}><i>{i18('noImage')}</i></div>
    } else {
      const source = api + '/image?hash=' + encodeURIComponent(hash) + 
      "&client=" + encodeURIComponent(client) + 
      "&user=" + encodeURIComponent(user) + 
      "&key=" + encodeURIComponent(clientkey);
      image = <img src={source} width={zoom?"100%":"20%"} onClick={(e)=>setZoom(!zoom)}></img>
    }
  }
  return  <div>{attribute ? attribute:""}{image}<br/>
    {mayUpload && onUploaded && !selectedFile && <form onSubmit={(e)=>{e.preventDefault();}}>
      <input type="file" accept="image/jpeg" onChange={(e)=>{
        e.preventDefault();
        setSelectedFile(e.target.files[0]); 
        }}/>
    </form> }
    {!uploadedHash && selectedFile ? <Button small={true} text={i18('upload')} onClick={()=>uploadImage(selectedFile)}/> : null}
    {!uploadedHash && selectedFile ? <Button small={true} text={i18('clear')} onClick={()=>setSelectedFile(null)}/> : null}
    </div>
}

export const Checkin = (props) => {
  const [value, setValue] = React.useState(null);
  const [values, setValues] = React.useState({});
  const [redirect, setRedirect] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [meta, setMeta] = React.useState(null);
  const [client, setClient] = React.useState(props.client);
  const [serial, setSerial] = React.useState(props.serial);
  const [edits, setEdits] = React.useState({});
  const [submitEdits, setSubmitEdits] = React.useState(false);
  const [checkedProfileName, setCheckedProfileName] = React.useState("");
  const [positioning, setPositioning] = React.useState(false);
  const [unclaimed, setUnclaimed] = React.useState(false);
  const [localStyle, setLocalStyle] = React.useState(null);
  const key = props.clientkey;

  // Effect for doing checkin call
  React.useEffect(() => {
    let cancelled = false;
    const fetchFunc = async () => {
      if (!(serial&&client))
        return;
      const call = api + '/checkin?name=' + encodeURIComponent(serial) + 
        "&client=" + encodeURIComponent(client) + 
        "&user=" + encodeURIComponent(props.user) + 
        "&key=" + encodeURIComponent(key);
      console.log("Calling " + call);
      try {
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          console.log("Checkin ok");
          setPositioning(data.requestPosition);
          setValue(data.value);
          setValues(data.values);
          setEvents(data.events);
          setCheckedProfileName(data.profileName);
        }
      } catch (err) {
        console.log("Failed checkin for serial " + serial +": ", err);
        (!cancelled) && props.onDone("Failed to checkin serial '" + serial + "'");
      }
    }
    fetchFunc();
    return ()=>{cancelled=true;}
  }, [serial, client]);

  // Effect for decoding hash to set serial and client
  React.useEffect(() => {
    if (serial) {
      console.log("Cancelling hash retreival: serial, client:", serial, client);
      return; // Do not run this one if we already have serial or client
    }
    let cancelled = false;
    const f = async () => {
      try {
        const call = api + '/rehash?hash=' + encodeURIComponent(props.hash);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          if (data.hasOwnProperty('unclaimed')) {
            setUnclaimed(true);
          } else {
            console.log("Hash rehashed ok -> ", data.serial, data.client);
            setSerial(data.serial);
            setClient(data.client); // Is only for this function component instance
            setLocalStyle(data.style); // Is only for this function component instance
          }
        }
      } catch (e) {
        (!cancelled) && props.onDone(i18('errorConnectionFailed'));        
      }
    }
    f();
    return ()=>cancelled=true;
  }, [props.hash]);


  // Effect for redirects
  React.useEffect(()=> {
    if (!redirect)
      return;
    if (!(serial && client))
      return;
    let cancelled = false;
    const f = async() => {
      const call = api + '/event?name=' + encodeURIComponent(serial) + "&client=" + encodeURIComponent(client) + "&user=" + encodeURIComponent(props.user) + "&action=" + redirect.name;
      console.log("calling " + call);
      try {
        await fetch(call);
      } catch (e) {console.log(e.message)}
      if (!cancelled) {
        if (key) {
          // Logged in user
          console.log("opening " + redirect.url);
          window.open(redirect.url, "qrltarget");
          props.onDone(null);
        } else {
          const meta = <meta http-equiv="refresh" content={"0;url=" + redirect.url} />;
          setMeta(meta);
        }
      }
    }
    f();
    return ()=>{cancelled=true;}
  }, [redirect, key, serial, client]);

  // Effect for submitting edits
  React.useEffect(() => {
    let cancelled = false;
    if (!submitEdits)
      return;
    const fetchFunc = async () => {
      if (!(serial&&client))
        return;
      const call = api + '/edits?name=' + encodeURIComponent(serial) + 
        "&client=" + encodeURIComponent(client) + 
        "&user=" + encodeURIComponent(props.user) + 
        "&key=" + encodeURIComponent(key) +
        "&edits=" + encodeURIComponent(JSON.stringify(edits));
      console.log("Calling " + call);
      try {
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          props.onDone(i18('updated'));
        }
      } catch (err) {
        console.log("Failed submit for serial " + serial +": ", err);
        (!cancelled) && props.onDone(i18('errorSubmitFailed'));
      }
    }
    fetchFunc();
    return ()=>{cancelled=true;}
  }, [submitEdits]);

  // If we got here with an unclaimed node
  if (unclaimed) {
      let buttons, info;
      if (key) {
        buttons = <div><Button text={i18('cancel')}  onClick={()=>props.onDone(null)}/><Button  onClick={()=>props.onDone('claim')} text={i18('claim')}/></div>;
        info = <div>{i18('claimInfo')}<br/><br/>{i18('qrl')} {props.hash}</div>
      }
      else {
        info = i18('unclaimedInfo');
        buttons = <Button text={i18('login')} onClick={()=>props.onDone(null)}></Button>;
      }
      return <div>{i18('unclaimed')}<hr/>
      <div style={{fontSize:smallestFontSize}}>{info}</div>
      <hr/>
        {buttons}
      </div>
  }

  let background = localStyle && localStyle.background ? localStyle.background : bg;
  let color = localStyle && localStyle.color ? localStyle.color : fg;
  const tmpStyle = {background, color, position:"fixed", top:"0vh", bottom:"0vh", left:"0vw", right:"0vw", overflow:"auto"}
  const tmpInnerStyle = {paddingTop:"10vh"};

  // If there is something to show, first do the positioning (possibly with user interaction)
  if (positioning) {
    return <div style={tmpStyle}><div style={tmpInnerStyle}>{i18('positioning')}<hr/>
      <Positron setEvents={setEvents} positioning={positioning} setPositioning={setPositioning} user={props.user} clientkey={key} serial={serial} client={client} hash={props.hash}/></div></div>
  }
  
  // Redirect
  if (meta) {
    return <div style={tmpStyle}><div style={tmpInnerStyle}>
      {client}<hr/><div style={{fontSize:smallestFontSize}}>{i18('redirecting')}</div>
      {meta}</div></div>;
  }

  if (redirect) {
    console.log("Redirecting");
    return <div style={tmpStyle}><div style={tmpInnerStyle}>{i18('processing')}</div></div>
  }

  if (submitEdits) {
    return <div style={tmpStyle}><div style={tmpInnerStyle}>{i18('processing')}</div></div>
  }

  // No values retreived yet
  if (!value) {
    return <div style={tmpStyle}><div style={tmpInnerStyle}>{i18('processing')}</div></div>;
  }

  if (!(serial && client)) {
    if (props.hash) {
      console.log("What is this state?", props.hash, "serial:", serial, "client:", client);
      return <div style={tmpStyle}><div style={tmpInnerStyle}>{i18('processing')}</div></div>
    }
    return <div>Error, missing serial or client</div>;
  }


  // If there is only one option and it is an URL, redirect immediately
  let urlCount = 0;
  let nonUrlCount = 0;
  if (values) {
    let lastUrl = null;
    Object.keys(value).map((k) => {
      if ((typeof value[k] === "string") && isUri(value[k])) {
        urlCount++;
        lastUrl = value[k];
      } else
        nonUrlCount++;
    });
    if (0 === nonUrlCount && 1 === urlCount) {
      setRedirect({url:lastUrl, name:"Redirect"});
    }
  }

  const onAction = (url, name) => {
    setRedirect({url, name});
  }

  const onSubmitEdits = () => {
    setSubmitEdits(true);
  }

  // Remove fields that are duplicated from UI
  const fields = Object.keys(value);
  let doubleFields = {};
  for (let i = 0; i < fields.length; ++i) {
    const field = fields[i];
    const val = value[field];
    console.log("Checking field " + field + " value ", val);
    let f = isEdit(val);
    if (f) {
      doubleFields[f] = true;
      console.log("Adding field " + f + " to double fields map", value);
      continue;
    }
    f = isMultiLineEdit(val);
    if (f) {
      doubleFields[f] = true;
      console.log("Adding field " + f + " to double fields map");
      continue;
    }
  }

  let visibleFields = [];
  for (let i = 0; i < fields.length; ++i) {
    const field = fields[i];
    if (doubleFields.hasOwnProperty(field))
      continue;
    visibleFields.push(field);
  }

  // TBD: Cover whole screen for this usecase?
  return (<div style={tmpStyle}>
    <div style={tmpInnerStyle}>
  {checkedProfileName ? checkedProfileName:i18('serial')} {serial}
  {(value && nonUrlCount>0) && <hr></hr>}
  {value && <table style={tableStyle}>
    <tbody>
    {
      values && 
        visibleFields.map((k) => {
          if ((typeof value[k] === "string") && isUri(value[k]))
            return null;
          if ((typeof value[k] === "string") && isEdit(value[k])) {
            let attribute = isEdit(value[k]);
            let visibleValue = edits.hasOwnProperty(attribute) ? edits[attribute] : (value.hasOwnProperty(attribute) ? value[attribute] : "");
            return <tr valign="top" key={k}><td>{values[k].name}</td><td><Input value={visibleValue} onChange={(e)=>{
              let edited = {...edits};
              edited[attribute] = e.target.value;
              setEdits(edited)}}/>
            </td></tr>
          }
          if ((typeof value[k] === "string") && isMultiLineEdit(value[k])) {
            let attribute = isMultiLineEdit(value[k]);
            let visibleValue = edits.hasOwnProperty(attribute) ? edits[attribute] : (value.hasOwnProperty(attribute) ? value[attribute] : "");
            return <tr valign="top" key={k}><td>{values[k].name}</td><td><textarea rows="6" cols="40" value={visibleValue} onChange={(e)=>{
              let edited = {...edits};
              edited[attribute] = e.target.value;
              setEdits(edited)}}/>
            </td></tr>
          }

          if ((typeof value[k] === "string") && typeof(isImage(value[k]))==="string") {
            const image = isImage(value[k]);
            return <tr valign="top" key={k}><td>{values[k].name}</td><td>
              <ImageView hash={image} client={client} user={props.user} clientkey={key} onUploaded={(hash)=>{
              let edited = {...edits};
                edited[k] = "img:"+hash;
                setEdits(edited);
              }} />
              </td></tr>
          }

          return <tr key={k}><td>{values[k].name}</td><th>{value[k]}</th></tr>
        })
    }
    </tbody>
  </table>}
  {urlCount>0 && <hr></hr>}
  {values && Object.keys(values).map((k) => {
      if ((typeof value[k] === "string") && isUri(value[k]))
        return <button key={k} style={{...buttonStyle, background:values[k].public ? buttonHighlightColor : buttonStyle.background}} onClick={()=>onAction(value[k], values[k].name)}>{values[k].name}</button>
      return null;
    }) 
  }
  {key && value && <Button onClick={()=>{props.onDone(null);}} text={i18('done')}/>}
  {edits && Object.keys(edits).length!==0 && <Button onClick={()=>onSubmitEdits()} text={i18('submit')}/>}
  {key && events && events.length>0 && <ShowLog detached={true} serial={serial} events={events} setHideMap={props.setHideMap}/>}
  <div style={{position:"fixed", left:"2vmin", bottom:"2vmin", fontSize:smallestFontSize, textAlign:"right", margin:"1vmin"}}>{client}</div>
  </div>
  </div>
  );
}

const MiniButton = (props) => {
  const {k, value, values} = props;
  const [expand, setExpand] = React.useState(false);
  if (expand)
    return <div style={{fontSize:"2vmin"}} onClick={()=>setExpand(!expand)}>{value[k]} <a href={value[k]} target="other">[{i18('go')}]</a></div>;
  else
    return <div onClick={()=>setExpand(!expand)} style={{...buttonStyle, margin:"0.1vmin", padding:"0.1vmin", minWidth:"4vmin", maxWidth:"14vmin", fontWeight:400, fontSize:"2vmin"}}>{values[k].name}</div>
}


const ListRow = (props) => {
  const [view, setView] = React.useState(0);
  const [value, setValue] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [events, setEvents] = React.useState(null);
  const [position, setPosition] = React.useState([0,0]);
  
  const {addSeries, addCsv, setHideMap, active, setActive, setCenter, compact} = {compact:true, ...props};

  const formatValue = (k, value, values) => {
    if ((!value) || (!values[k]) || value[k] === undefined || value[k] === null)
      return null;
    if (compact && (!value[k] || value[k]==="img:"))
      return null;
    if (isUri(value[k]))
      return <MiniButton k={k} value={value} values={values} /> 
    if (isEdit(value[k]))
      return null;
    if (isMultiLineEdit(value[k]))
      return null;
    if (isImage(value[k]))
      return <ImageView hash={isImage(value[k])} client={props.client} user={props.user} clientkey={props.clientkey}/>
    if (typeof(value[k]) === "string")
      return <div style={{fontSize:smallestFontSize}}>{values[k].name + ":" + value[k] + " "}</div>;
    return <div>{values[k].name + ":" + JSON.stringify(value[k]) + " "}</div>;
  }


  React.useEffect(()=>{
    let cancelled = false;
    if (view !== 0)
      return;
    const f = async () => {
      const call = api + '/checkin?name=' + encodeURIComponent(props.serial) + 
        "&client=" + encodeURIComponent(props.client) + 
        "&user=" + encodeURIComponent(props.user) + 
        "&key=" + encodeURIComponent(props.clientkey) + 
        "&nocheckin=true";
      try {
        console.log("Calling " + call);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setValue(data.value);
          setValues(data.values);
          setEvents(data.events);
          let csvData = props.serial + "; ";
          let lat, lng, ts, action, pts;
          if (data.events) {
            data.events.map((e, i) => {
              if (e.lat && e.lng) {
                lat = e.lat; lng = e.lng; pts=e.timestamp;
              }
              if (e.timestamp)
                ts = e.timestamp;
              if (e.action)
                action = e.action;
            });
          }
          if (lat && lng && pts) {
            addSeries({serial:props.serial, lat, lng, timestamp:new Date(pts), title:i18('serial') + " " + props.serial, text:action, active:active===props.serial});
            setPosition([lat, lng]);
          }
          csvData += "lastAction; " + action + "; timestamp; " + ts + "; positionTimestamp; "+ pts+ "; pos; " + lat + "," + lng + "; ";
          if (data.value)
            Object.keys(data.value).map((k)=>{console.log(k, data.value[k]); csvData+=k + "; " + data.value[k] + ";"})
          addCsv(csvData);
          setView(1);
        }
      } catch (err) {
        (!cancelled) && setView(2);
      }
    }
    f();
    return ()=>cancelled=true;
  }, [view, props.serial]);

  if (view === 2) {
    return <tr style={{...smallestText, color:"#888",}} key={props.serial}><td align="right">{props.serial}</td><td/><td/></tr>
  }

  if (view === 1) {
    const list = [<td align="right">{props.serial}</td>];
    if (events) {
      list.push(<td>{events.length?events[events.length-1].timestamp.substr(0,10):""}</td>);
      list.push(<td><ShowLog small={true} events={events} serial={props.serial} setHideMap={setHideMap}/></td>);
    }
    let vList=[];
    if (values)
      Object.keys(values).map((k,n) => {if (n < 20) list.push(formatValue(k, value, values))});
    list.push(<td><div>{vList.map(v=>v)}</div></td>);
    return <tr style={{...smallestText, background:(active===props.serial)?buttonColor:popupBackground}} 
               key={props.serial} onClick={()=>{setActive(props.serial); setCenter(position)}}
               onMouseEnter={()=>{/*setActive(props.serial); setCenter(position)*/}}>{list}</tr>
  }

  return <tr style={{...smallestText, color:"#4c4"}} key={props.serial}><td align="right">{props.serial}</td><td/><td/></tr>;
}

export const List = (props) => {
  const MAX_NUMBERS_PER_SEQUENCE = 50;
  const [view, setView] = React.useState(0);
  const [first, setFirst] = React.useState(props.serial ? props.serial : 0);
  const [last, setLast] = React.useState(props.serial ? parseInt(props.serial)+9 : 9);
  const [displaySerials, setDisplaySerials] = React.useState([]);
  const [csv, setCsv] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [zoom, setZoom] = React.useState(10);
  const [n, setN] = React.useState(0);
  const [hideMap, setHideMap] = React.useState(true);
  const [active, _setActive] = React.useState(null); // Set to serial when selected
  const [center, setCenter] = React.useState(series.length ? [series[0].lat, series[0].lon]:[0,0]);
  const [match, setMatch] = React.useState("");
  const [completions, setCompletions] = React.useState([]);
  const [expanded, setExpanded] = React.useState(true);
  const [time, setTime] = React.useState(0);
  const [autoMode, setAutoMode] = React.useState(false);
  const [compact, setCompact] = React.useState(true);

  React.useEffect(()=>{
    let ignore = false;
    console.log("Trying to complete '" + match + "'");
    let func = "completions"
    if (!match)
      func = "recents";
    const call = api + "/" + func +"?client=" + encodeURIComponent(props.client) 
    + "&key=" + encodeURIComponent(props.clientkey) 
    + "&user=" + encodeURIComponent(props.user)
    + "&match=" + encodeURIComponent(match);
    fetch(call)
      .then(response => response.json())
      .then(data => {if(!ignore){
        setCompletions(data);
        setDisplaySerials(completions);
      }});
    return () => {ignore=true;}
  }, [match, time]);

  React.useEffect(()=> {
    let handle = null;
    if (autoMode)
      handle = setTimeout(()=>{setTime(time+8)}, 8000);
    return ()=>clearTimeout(handle);
  }, [time, match, autoMode]);

  const addCsv = (line) => {
    console.log("Adding csv line:", line);
    csv.push(line);
  }
  const addSeries = item => {
    series.push(item);
    setN(Math.random());
    console.log("addSeries: ", series);
  }
  const setActive = serial => {
    _setActive(serial);
    let newSeries = series.slice();
    for (let i = 0; i < newSeries.length; ++i) {
      newSeries[i].active=newSeries[i].serial === serial;
    }
    console.log("new series:", newSeries);
    setSeries(newSeries);
    setN(Math.random());
  }

  const onCopyCsv = () => {
    const sorted = csv.sort((a,b) => a<b ? -1 : 1);
    const text = sorted.join("\n");
    console.log("Produced CSV:", text);
    navigator.clipboard.writeText(text);
  }

  const checkSequence = () => {
    let f = parseInt(first);
    let l = parseInt(last);
    if (f >= 0 && l >= 0 && f <= l && l-f<MAX_NUMBERS_PER_SEQUENCE)
      return true;
    return false;
  } 
  
  const updateLastByFirst = (first) => {
    if (parseInt(first) > 0) {
      setLast(parseInt(first)+9);
      generateDisplaySerialFromTo(parseInt(first), parseInt(first)+9);
    }
  }

  const generateDisplaySerialFromTo = (from, to) => {
    let array = [];
    from = parseInt(from);
    to = parseInt(to);
    console.log(from, to);
    if (Number.isInteger(from) && Number.isInteger(to) && from < to && to-from <= MAX_NUMBERS_PER_SEQUENCE) {
      for (let i = from; i <= to; ++i) {
        console.log(i);
        array.push(i);
      }
    }
    setDisplaySerials(array);
  }

  const count = parseInt(last)-parseInt(first)+1;

  if (view === 0)
    return <div style={pageStyle}>{i18('selectRange')}<div style={{...horizontalStyle,marginTop:"1vmin", color:softColor, fontSize:smallestFontSize}}>{i18('noteAboutApi')}<Help text="helpAPI"/></div>
      <hr/>
        <Input key="first" placeholder={i18('first')} hint={i18('firstHint')} type="number" value={first} 
          onChange={(e)=>{setFirst(e.target.value); updateLastByFirst(e.target.value);}}
          complete={(t)=>setMatch(t)} completions={completions}></Input>
        {first ? <Input key={"last"+first} placeholder={i18('last')} hint={i18('lastHint')} type="number" value={last} onChange={e=>setLast(e.target.value)}></Input> : null}
        {first ? checkSequence() && <div style={{fontSize:smallestFontSize}}>{count} {i18('serials')}</div> : null}
        {!checkSequence() && <div style={{fontSize:smallestFontSize}}>{i18('selectARangeOf')} 1-{MAX_NUMBERS_PER_SEQUENCE} {i18('serials')}</div>}
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone(null)}/>
      {first ? <Button disabled={!checkSequence()} text={i18('list')} onClick={()=>{setCsv([]);setAutoMode(false);generateDisplaySerialFromTo(first,last);setView(1)}}></Button> : null}
      {completions ? <Button disabled={(!completions)} text={(first ? i18('matching') : i18('latest')) + (completions? (" "+completions.length):"")} onClick={()=>{setCsv([]);setAutoMode(true);setDisplaySerials(completions);setView(1)}}></Button> : null}
      </div>;
  if (view === 1) {
    const rows = [];
    for (let i = 0; i <= displaySerials.length; ++i) {
      const s = displaySerials[i];
      rows.push(<ListRow key={s} serial={s} compact={compact} client={props.client} clientkey={props.clientkey} 
                        user={props.user} addCsv={addCsv} addSeries={addSeries} 
                        setHideMap={(b)=>{setHideMap(b); console.log("Hide map:", b)}}
                        setActive={setActive} active={active} 
                        setCenter={setCenter}/>);
    }
    return <div style={pageStyle}>{!hideMap ? null : (first ? (i18('range') + " " + first + "- " + last) : (displaySerials.length + " " + i18('matching')))}
      {!hideMap && <TimeSpace key={""+n+active+series.length} series={series} height="35vh" width="100vw" center={center} zoom={zoom} setZoom={setZoom} expanded={expanded} setExpanded={setExpanded}/>}
      {hideMap && <div key={n} style={{height:"2vh"}}/>}
      {first ? <Button small={true} text={i18("back") + " " + count} onClick={()=>{
        setCsv([]); 
        setSeries([]); 
        setAutoMode(false);
        setFirst(parseInt(first)-count); 
        setLast(parseInt(last)-count);
        generateDisplaySerialFromTo(parseInt(first)-count, parseInt(last)-count);}} disabled={parseInt(first)-count<0}/> : null }
      <Button small={true} text={i18("map")+(!hideMap?" ☑":"")} onClick={()=>{setHideMap(!hideMap)}}/>
      <Button small={true} text={i18("compact")+(compact?" ☑":"")} onClick={()=>{setCompact(!compact)}}/>
      {first ? <Button small={true} text={i18("next") + " " + count} onClick={()=>{
        setCsv([]); 
        setAutoMode(false);
        setSeries([]); 
        setFirst(parseInt(first)+count); 
        setLast(parseInt(last)+count);
        generateDisplaySerialFromTo(parseInt(first)+count, parseInt(last)+count);}}/> : null}
      <hr></hr>
      <div style={{maxHeight:hideMap ? "70vh":"40vh", height:hideMap?"70vh":"40vh", overflow:"auto"}}>
      <table style={{...tableStyle, fontSize:smallestFontSize, maxWidth:"100vw", width:"100vw"}}>
        <thead><th align="right" width="10%">{i18('serialShort')}</th><th width="10%">{i18('date')}</th><th width="10%">{i18('actions')}</th><th width="65%">{i18('values')}</th></thead>
        <tbody>
          {rows}
        </tbody>
      </table></div>
      <hr/>
      <Button text={i18('back')} onClick={()=>setView(0)}/>
      <Button text={i18('copyAsCSV')} onClick={()=>onCopyCsv()} />
      <Button text={i18('done')} onClick={()=>props.onDone(null)}/>
    </div>;
  }  
}