// Copyright (C) 2022, Rutio AB, All rights reserved

import React from 'react';
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const Positron = (props) => {
  const key = props.clientkey;
  const {user, client, serial, positioning, setPositioning, setEvents, hash} = props;
  const [sharePosition, setSharePosition] = React.useState(key ? isMobile : false);

  // Effect for setting location, should the key be set
  React.useEffect(() => {
    let cancelled = false;
    if (!serial)
      return;
    if (!positioning)
      return;
    if (!sharePosition && !isMobile) {
      setSharePosition(false);
      return;
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log(position);
      if (position && position.coords && position.coords.latitude && position.coords.longitude) {
        try {
          const call = api + '/updateposition?name=' + 
            encodeURIComponent(serial) + "&lat=" + position.coords.latitude + "&lng=" + position.coords.longitude  + 
            "&client=" + encodeURIComponent(client) + "&key=" + encodeURIComponent(key) + 
            "&user=" + encodeURIComponent(user) + "&hash=" + encodeURIComponent(hash);
          const response = await fetch(call);
          const data = await response.json();
          if (!cancelled) {
            setSharePosition(false); // To avoid double calls
            if (setEvents)
              setEvents(data);
            setPositioning(false); // - Automatically close
          }
        } catch (err) {
          console.log("position update: ", err.message);
          if (!cancelled)
            setPositioning(false);
        }
      }
    }, error => { 
      console.error("positioning: Error code = " + error.code + " - " + error.message); 
      if (!cancelled)
       setPositioning(false)});
    return ()=>{cancelled=true;}
  }, [key, serial, client, sharePosition]);

  if (!sharePosition)
    return <div>
      {!isMobile && <div>{i18('onComputer')}<br/></div>}
      {i18('sharePosition') + " " + client + "?"}
      <hr/>
      <Button text={i18('deny')} onClick={()=>setPositioning(false)}/>
      <Button text={i18('allow')} onClick={()=>setSharePosition(true)}/>
      </div>

  return <div>
    <Button text={i18('cancel')} onClick={()=>setPositioning(false)}/>
    </div>
}
