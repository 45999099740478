import React from 'react';
import { popupStyle, coverStyle, smallestFontSize, buttonColor, fg, widgetFontSize } from "../styles";
import { i18 } from '../i18n/i18';
import { CloseButton } from './Button';

export const Help = (props) => {
  const [show, setShow] = React.useState(false);
  const [left, setLeft] = React.useState(10);
  const [top, setTop] = React.useState(10);
  if (!show)
    return <div style={{fontSize:smallestFontSize, borderWidth:"1vmin", fontWeight:600, color:fg, 
                        background:buttonColor, width:widgetFontSize, height:widgetFontSize, margin:"1vmin",borderRadius:"2vmin"}} 
    onClick={()=>setShow(!show)}
    onMouseDown={(e)=>{setLeft(e.clientX); setTop(e.clientY)}}>?</div>;
  return <div style={coverStyle} onClick={()=>setShow(!show)}>
        <div style={{...popupStyle, position:'fixed', top, left:"10vw", maxWidth:"80vw", minWidth:"40vw"}}>{i18('help')}<hr/><small>{i18(props.text)}</small>
            <CloseButton/>
        </div>
    </div>
}
