import React from 'react';
import { buttonStyle, tableStyle, smallText, smallestFontSize, softColor } from "../styles";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';

const formatMoney = (m) => {
  if (typeof(m) === "string")
    m = parseFloat(m);
  let str = "";
  let decimals = Math.floor((m*100)%100).toString();
  if (decimals.length===1)
    decimals = "0" + decimals;
  let nondecimals = Math.floor(m).toString();
  let separated = "";
  for (let i = nondecimals.length-1, j = 0; i>=0; i--, j++)
    if (j%3==2)
      separated = " " + nondecimals[i] + separated;
    else
      separated = nondecimals[i] + separated;

  str += separated + "." + decimals;
  return str;
}

const AddOrder = (props) => {
    const [count, setCount] = React.useState(100);
    const [step, setStep] = React.useState(-1);
    const [price, setPrice] = React.useState(0);
    const [currency, setCurrency] = React.useState("");
    const [note, setNote] = React.useState("");
  
    React.useEffect(()=>{
      let cancelled = false;
      if (step !== -1)
        return;
      const f = async () => {
        try {
          const call = api + "/price?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(props.clientkey) + "&user=" + encodeURIComponent(props.user);
          const response = await fetch(call);
          const data = await response.json();
          if (!cancelled) {
            console.log(data);
            setPrice(parseInt(data.price));
            setCurrency(data.currency);
            setStep(0);
          }
        } catch (err) {
          (!cancelled) && setStep(5);
        }
      }
      f();
      return ()=>cancelled=true;
    }, [step]);
  
    React.useEffect(()=>{
      let cancelled = false;
      if (step !== 2)
        return;
      const f = async () => {
        try {
          const call = api + "/order?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(props.clientkey) + "&user=" + encodeURIComponent(props.user) + "&count=" + encodeURIComponent(count)  + "&note=" + encodeURIComponent(note);
          const response = await fetch(call);
          const data = await response.json();
          if (!cancelled) {
            console.log(data);
            setStep(4);
            props.setCredits(parseInt(data));
          }
        } catch (err) {
          (!cancelled) && setStep(5);
        }
      }
      f();
      return ()=>cancelled=true;
    }, [step]);
  
    if (step === -1) 
      return <div>{i18('preparing')}</div>
  
    if (step === 5)
      return <div>Order processing failed.<hr/>
        Please contact us for further information
        <hr/>
        <Button text={i18('back')} onClick={()=>setStep(0)}></Button>
        </div>;
  
    if (step === 4)
      return <div>{i18('thankyou')}
              <hr/><Button text={i18('done')} onClick={()=>props.onDone()} />
            </div>;
  
    if (step === 2)
      return <div>{i18('processing')}</div>
  
    if (step === 1)
      return <div>
        {i18('confirmOrder')}
        <hr/>
        <div align="left">
        <table style={tableStyle}><tbody>
        <tr><td>{i18('organization')}</td><td>{props.client}</td></tr>
        <tr><td>{i18('user')}</td><td>{props.user}</td></tr>
        <tr><td>{i18('quantity')}</td><td>{count} {i18('serials')}</td></tr>
        <tr><td>{i18('total')}</td><td>{formatMoney((count*price)/100*1.25)} {currency}</td></tr>
        <tr><td>{i18('note')}</td><td>{note}</td></tr>
        </tbody></table>
        </div>
        <hr/>
        <Button text={i18('back')} onClick={()=>setStep(0)}></Button>
        <Button text={i18('confirm')} onClick={()=>setStep(2)}></Button>
      </div>;
  
    return <div>
      {i18('newOrder')}
      <div style={{marginTop:"2vmin", fontSize:smallestFontSize, color:softColor}}>{i18('vatRegisterred')}</div>
      <hr></hr>
      <table style={tableStyle}><tbody>
      <tr>
        <td>{i18('unitPrice')}</td>
        <th style={{textAlign:"right"}}>{price/100.0}</th>
        <td>{currency}</td>
      </tr>
      <tr>
      <td>{i18('quantity')}</td>
      <td>
      <select style={{...buttonStyle, textAlign:"right"}} value={count} onChange={(e) => {setCount(e.target.value); console.log("count:", e.target.value)}} >
        <option key="100" value="100">100</option>
        <option key="500" value="500">500</option>
        <option key="1000" value="1000">1000</option>
        <option key="5000" value="5000">5000</option>
        <option key="10000" value="10000">10000</option>
        </select></td>
        <td>{i18('serials')}</td>
        </tr>
        <tr><td>{i18('exVat')}<br/></td><td style={{textAlign:"right"}}>{formatMoney((count*price)/100)}</td><td>{currency}</td></tr>
        <tr><td>{i18('vat')}<br/></td><td style={{textAlign:"right"}}>{formatMoney((count*price)/100*0.25)}</td><td>{currency}</td></tr>
        <tr><td>{i18('total')}<br/></td><th style={{textAlign:"right"}}>{formatMoney((count*price)/100*1.25)}</th><td>{currency}</td></tr>
      </tbody></table>
      <hr/>
      <Input placeholder={i18('note')} hint={i18('orderNoteHint')} value={note} onChange={(e)=>setNote(e.target.value)}/>
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone()}/>
      <Button text={i18('next')} onClick={()=>setStep(1)}/>
    </div>
  }
  
  export const Orders = (props) => {
    const [addOrder, setAddOrder] = React.useState(false);
    const [orders, setOrders] = React.useState(null);
  
    React.useEffect(()=>{
      let cancelled = false;
      const f = async () => {
        try {
          const call = api + "/orders?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(props.clientkey) + "&user=" + encodeURIComponent(props.user);
          const response = await fetch(call);
          const data = await response.json();
          if (!cancelled) {
            console.log(data);
            setOrders(data);
          }
        } catch (err) {
          console.log("Failed order retreival", err);
          (!cancelled) && props.onDone("Failed to load orders.");
        }
      }
      f();
      return ()=>cancelled=true;
    }, [addOrder]);
  
    if (addOrder) {
      return <AddOrder client={props.client} clientkey={props.clientkey} user={props.user} credits={props.credits} setCredits={props.setCredits} onDone={()=>setAddOrder(false)}/>
    }

    const MAX_ORDERS = 50;
    return <div>
      {i18('ordersFor')} {props.client}
      <hr/>
        {!orders && <td>Loading...</td>}
        {Array.isArray(orders) && <div style={{maxHeight:"60vh", overflow:"auto"}}>
          <table style={{...tableStyle, textAlign:"right", borderSpacing:"2vmin"}}><tbody>
          <tr style={{textAlign:"left"}}><td>{i18('credits')}</td><td>{i18('totalExVat')}</td><td>{i18('date')}</td><td>{i18('state')}</td><td style={{textAlign:"left"}}>{i18('note')}</td></tr>
          {orders.map((o, i)=>{return i>=orders.length-MAX_ORDERS ? 
          <tr key={o.timestamp}><td style={{color:softColor}}>{o.credits}</td><td>{formatMoney(o.amount)} {o.currency}</td><td>{o.timestamp.substring(0, 10)}</td><td>{o.state}</td><td style={{textAlign:"left"}}>{o.note}</td></tr>:null})}
          </tbody></table></div>}
      <hr/>
      {<div style={{smallText}}>{i18('credits')}: {props.credits} {i18('serials')}</div>}
      <hr/>
      <Button onClick={()=>{setAddOrder(true)}} text={i18('newOrder')}></Button>
      <Button onClick={()=>{props.onDone(null)}} text={i18('done')}></Button>    
    </div>;
  }
  