export const en = {
  // General
  language: 'English',
  slogan: 'Smart QR Code and NFC Tracking Solutions',
  appname: 'Qrlio',

  // Skapa konto
  registerAccount: 'Register',
  organization: 'Organization',
  welcome: 'Welcome',
  whatKind: 'What type of asset would you like to track?',
  nameYourThing: 'Name your model (singular)',
  thingHint: 'Package, Installation, Sensor, Vehicle, Plant, ...',
  generalInformation: 'General Information',
  contactInformation: 'Contact Information',
  billingInformation: 'Billing Information',
  storageOptions: 'Storage Options',
  adminInformation:   'Administrator Account',
  organizationNameHint: "Public name of legal entity",
  countryCode: "Country Code",
  countryCodeHint: "Two letter country code, e.g. DK for Denmark",
  contactEmail: "Contact Email",
  contactPhone: "Contact Phone",
  website: "Website",
  contactEmailHint: "Who to contact in commercial and technical matters",
  contactPhoneHint: "Optional",
  websiteHint: "Public website of organization",
  billingOrgId: "Billing Org Id",
  billingOrgIdHint: "Organization Tax Identification Code",
  billingEmail: "Billing Email",
  billingEmailHint: "Email to send our invoices to",
  preferredCurrency: "Preferred Currency",
  preferredCurrencyHint: "Which is the preferred currency",
  billingReference: "Internal Billing Reference",
  billingReferenceHint: "Your Reference / Internal order no",
  supplier: "Data Supplier",
  usingContactEmail: "Using contact email address as login",
  createPasswordHint: "Decide a 5+ letters password for Qrlio.com login",
  acceptTermsAndConditions: "Accept Terms and Conditions",
  accountCreated: "Account created. Please log in using the credentials you supplied",
  odoo: 'ODOO - Trial on for example odoo.com',
  yggio3: 'Yggio 3 - By www.sensative.com',
  qrlio: 'Qrlio.com - Our servers in Sweden',
  custom: 'Custom - Contact info@qrlio.com for details',  
  server: "Server",

  // Inloggning
  login: 'Login',
  username: 'User name (email)',
  password: 'Password',
  loginFailed: 'Login failed, check username and password',

  // Default view
  enterSerial: 'Enter a Serial Number',
  serial: 'Serial Number',
  serials: 'Serial Numbers',
  serialHint: 'Your unique number used for tracking',
  registerSerial: 'Create',
  checkIn: 'Check In',
  showSerialQR: 'Show QR',
  scan: 'Scan a QR code or NFC tag from ',

  // Image View
  noImage: 'No image',
  upload: 'Upload',
  clear: 'Clear',

  // Claim
  claimSerial: 'Link this QRL to a new Serial Number',

  // Checkin
  onComputer: 'You appear to be using a computer, it may not have a correct position. ',
  sharePosition: 'Upload current position to ', 
  allow: 'Allow',
  deny: 'Deny',
  unclaimed: 'Unclaimed QRL',
  unclaimedInfo: 'Log in to claim this as yours and register data with it',
  claimInfo: 'Do you wish to claim this QRL?',
  claim: "Claim",

  // Registration
  selectProfile: 'Select Model',

  // QRL
  QRCodeFor: 'QR code for',
  failedQRpt1: 'Failed to create QR for ',
  failedQRpt2: ' - is it registerred?',

  // Users
  user: 'User',
  usersForClient: 'Users with ',
  access: 'Access',
  failedDeleteUser: 'Failed to delete user - if it is your own you have to ask another admin to delete the account',
  usernameHint: 'Recommend an email address',
  passwordHint: 'At least 5 characters',
  accessHint: 'A:Admin, R:Register, L:List, U:Upload images, x: You decide',
  deleteUser: 'Delete User',
  confirmPassword: 'Confirm password',
  confirmPasswordHint: 'Enter the same password again',
  changedOwnPassword: 'You have changed your own password. You need to log in again',

  // Profiles
  profile: 'Model',
  profilesFor: 'Models for',
  name: 'Name',
  note: 'Note',
  nameHint: 'Must be unique, can include letters and character "-"',
  profileNoteHint: 'Notes about this model',
  deleteProfileNotice: 'Note: A deleted model will remain invisible in the system in order not to break QRs or NFC tags using it. You cannot delete all models.',
  editProfileValues: 'Edit Values',
  values: 'Values',
  valuesHint: 'Properties and actions for this model',
  useSupplierNode: 'Enhance existing IoT nodes',
  useSupplierNodeHint: 'If true, the model connects to and updates existing data with your data supplier',
  requestPublicPosition: 'Request position by unknown users',
  requestPublicPositionHint: 'If true, when an unknown user checks in we ask them for position data',
  helpRequestPublicPosition: `Use this with care. Users who are not logged in will be asked if they would like
  to share their position with your company. They may accept or decline. Depending on your local jurisdiction this
  may be subject to regulations such as GDPR. You are responsible for any data collection that can be traced back 
  to individuals.`,

  // Supplier Node Selector
  supplierNode: 'Original IoT Node',
  supplierNodeHint: 'Type part of or whole name, press Select for making a selection',
  supplierNodeSelect: 'Select',

  // Values
  value: 'Value',
  valuesFor: 'Values for',
  attribute: 'Name',
  label: 'Label',
  labelHint: 'How this value is labelled to visitors',
  defaultValue: 'Default',
  defaultHint: 'Default value when registering new serials',
  public: 'Public',
  publicHint: 'If true it is visible to anyone',
  editable: 'Editable',
  editableHint: 'If true this value can be edited when registering',

  // Data
  dataFor: 'Templates for',
  willBeReplacedByThis: 'will be replaced by this',

  // Access Log
  accessFor: "GDPR Access Log for ",

  // Orders
  newOrder: 'New Order',
  credits: 'Credits',
  ordersFor: 'Orders for',
  quantity: 'Quantity',
  exVat: 'Subtotal',
  vat: 'VAT 25%',
  total: 'Total',
  totalExVat: 'Total (excl. VAT)',
  unitPrice: 'Unit Price',
  orderNoteHint: 'This note will be included on invoice',
  confirmOrder: 'Confirm Order',
  date: 'Date',
  state: 'State',
  vatRegisterred: 'Rutio AB is registerred in EU for VAT: SE559222341501',

  // Supplier
  status: 'Status', 
  warning: 'Information',
  supplierChangeWarning: 'The new account or server needs to have the information present on the old account. If not some QR codes or NFC tags may stop working.',

  // Listing
  selectRange: 'Select Range',
  range: 'Range',
  first: 'First',
  firstHint: 'First serial in range',
  last: 'Last',
  lastHint: 'Last serial in range',
  selectARangeOf: 'Select a range of ',
  lastEvent: 'Last Event',
  serialShort: 'Serial',

  // Printing several
  layout: 'Print Layout',

  // Register several
  check: 'Check',
  created: 'Created',
  creditsLeft: 'Credits remaining',

  // General buttons
  cancel: 'Cancel',
  back: 'Back',
  next: 'Next',
  ok: 'Ok',
  done: 'Done',
  select: 'Select',
  confirm: 'Confirm',
  edit: 'Edit',
  add: 'Add',
  submit: 'Submit',
  delete: 'Delete',
  users: 'Users',
  orders: 'Orders',
  profiles: 'Models',
  data: 'Templates',
  onedata: 'Template',
  copyURL: 'Copy URL',
  show: 'Show',
  hide: 'Hide',
  actions: 'Actions',
  compact: 'Compact',
  log: 'Log',
  logFor: 'Latest entries for',
  copyAsCSV: 'Copy as CSV',
  logout: 'Log out',
  list: 'List Interval',
  matching: 'Matching',
  latest: 'Latest',
  map: 'Karta',
  go: 'Go',

  // Terms and Conditions
  byLoggingInYouAgree: 'By logging in to this service you agree to our',
  termsIntroduction: `This Service is targeted to organizations wanting to track their assets. 
  It does not track people not affiliated with our Customers.
  This reflects in our Privacy Policy and in our Terms and Conditions.`,

  // General statements
  preparing: 'Preparing...',
  processing: 'Processing...',
  positioning: "Positioning",
  registering: "Registering",
  redirecting: "Redirecting...",
  loading: "Loading...",
  submitting: "Submitting",
  thankyou: "Thank you very much",
  updated: "Data has been updated",
  cancelled: "Operation was cancelled",

  // Menus
  administratorMenu: 'Administrate',
  registratorMenu: 'Register',
  printSeveral: 'Print QR Codes',
  registerSeveral: 'Register Serials',
  accessLog: 'GDPR Access Log',

  noteAboutApi: 'Use the Qrlio API to make custom design',

  // Error messages
  error: 'Message',
  errorNoProfileSelected: 'No template selected',
  errorMissingInformation: 'Information missing',
  errorFailedRegister: 'Failed to create serial, does it already exist?',
  errorConnectionFailed: 'Temporary problem with connection, please try again soon',
  errorAccountCreateFailed: 'Failed to register organization',
  errorAccountCreateFailed2: 'The account may already exist or some information was rejected.',
  errorAccountCreateFailed3: 'Please contact us at info @ qrlio.com should the problem persist.',
  errorCouldNotDeleteProfile: 'Could not delete profile',
  errorSubmitFailed: 'Failed to submit values',
  errorCannotRegister: 'Ensure that you have the R right required for registerring new units',

// Help
help : "Whats this?",
helpSerial: `A serial number is a number you decide on for representing one of your Assets.
  If you have the R access you can register new serial numbers here or ranges of serial numbers 
  using the Register Serials command. 
  Once you have a serial number you can use it to generate QR codes or get URLs to encode in a NFC tag`,
helpProfiles: `Models represent the various kinds of objects your organization tracks using this service.
  Should your organization be a bike rental service, your model would primarily be bikes.
  Each model may also have a set of values on top of the serial number. 
  For instance a bike may have a brand and a date of purchase. You decide which values to add to your models.
  Keep the models as general as possible. Should you have many it is better to reference them (as a value 'type') than
  making a new model for each thing you have. There are other inventory systems that does that much better.`,
helpDatas: `Templates define standard actions and texts for your organization in one place.
  You can refer to templates in the values of each object type, and templates may also refer to values in each object.
  Instead of copying the URL into each object you can compose the URL as a value here.
  If you have an url such as https://company.com?serial={value.serial}, you can add it here by the name homepage. 
  Then include it in your Object Type's default value as the text {homepage}. By this you will be able to change 
  in one single place should the webpage address later be replaced`,
helpData: `A template has a name which should be including only letters and the hyphen (-) character.
  Templates can refer to each other and to objects by using the syntax {other-name}, in which case the part inside {} 
  is replaced by the named value.`,
helpValue: `Each value has a name, a label, a default value, keys and information if it can be modified and it is public.
  The name is used to refer to this value from other values or from templates.
  The label is the visible name presented to users of the system. The access is used to assign which users can see (or update) the value.
  Should the value be marked as public it means that anyone can see the value, even without having a user account.
  The default value is what will be registerred with new serial numbers, unless it is marked as editable in which case the person who
  registers new serial numbers will be asked to input values.`,
helpDeft: `The value can be either an action, an input or a value. If it begins with http, mailto: or tel: it will be 
  treated as the corrsponding URL by the system. If it is on the format edit:attribute it will instead generate a
  text editor when visible to a user, and the value.attribute is updated. If it is any other text it will be just 
  displayed as plain text. You can refer to other values and templates by {x} where x is the name of the template or other value.
  It is highly recommended to only store values that are specific for the object here, and otherwise refer to templates.`,
helpUseSupplierNode: `If this is set to true it means Qrlio will write its data into existing IoT nodes.
  This will allow you to add new data and functions for the IoT node but you can also risk overwriting data. 
  Ensure that any value have unique names comparred to your IoT node contents.`,
helpAPI: `Visit https://github.com/Rutio-se/qrlio-scripting for more information on the public API 
of Qrlio.com and how to adapt it to the needs of your organization.`,
};

