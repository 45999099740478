// Copyright (C) 2022, Rutio AB

// javascript style declarations

const isLandscape = window.innerWidth>window.innerHeight;

const multiplyColor = (c, factor, transp = "f") => {
  const two = (s) => (s.length === 2) ? s : ("0"+s);
  let result = c;
  if ((c.length === 4 || c.length===5) && c[0]==="#") {
    let r = Math.min(15,Math.floor(factor*parseInt(c.substring(1,2),16))); 
    let g = Math.min(15,Math.floor(factor*parseInt(c.substring(2,3),16)));
    let b = Math.min(15,Math.floor(factor*parseInt(c.substring(3,4),16)));
    result = "#"+r.toString(16)+g.toString(16)+b.toString(16)+transp;
  }
  else if ((c.length === 7 || c.length===9) && c[0]==="#") {
    let r = Math.min(255,Math.floor(factor*parseInt(c.substring(1,3),16))); 
    let g = Math.min(255,Math.floor(factor*parseInt(c.substring(3,5),16)));
    let b = Math.min(255,Math.floor(factor*parseInt(c.substring(5,7),16)));
    result = "#"+two(r.toString(16))+two(g.toString(16))+two(b.toString(16)) + transp + transp;
  }
  else {
    console.log("multiplyColor failed", c, factor, transp);
  }

  return result;
}

const isDark = (c) => {
  let result = true;
  if ((c.length === 4 || c.length===5) && c[0]==="#") {
    let r = Math.min(15,Math.floor(parseInt(c.substring(1,2),16))); 
    let g = Math.min(15,Math.floor(parseInt(c.substring(2,3),16)));
    let b = Math.min(15,Math.floor(parseInt(c.substring(3,4),16)));
    result = r+g+b < 8*3;
  }
  else if ((c.length === 7 || c.length===9) && c[0]==="#") {
    let r = Math.min(255,Math.floor(parseInt(c.substring(1,3),16))); 
    let g = Math.min(255,Math.floor(parseInt(c.substring(3,5),16)));
    let b = Math.min(255,Math.floor(parseInt(c.substring(5,7),16)));
    result = r+g+b < 128*3;
  }
  else {
    console.log("IsDark failed");
  }

  return result;
}

// This implements night/day mode. 
// However, the intention is a preparation for client specific coloring by changing bg and fg.
const hours = new Date().getHours();
const day = ((hours>=8) && (hours <= 18));
export const bg=day ? "#28422c" : "#28302c";
export const dark = isDark(bg);
export const fg=isDark(bg)?"#eee":"#111";
export const widgetFontSize=isLandscape ? "2.5vmin" : "3.5vmin";
export const smallestFontSize=isLandscape ? "1.8vmin" : "2vmin";
export const inputStyle={fontSize:widgetFontSize, 
  width:"97%", 
  background:"#eee",
  color:"000",
};
export const coverStyle={position:'fixed', left:0, top:0, bottom:0, right:0, color:"#ddd", background:"#222C"};
export const popupBackground=multiplyColor(bg, dark?1.5:0.8, "c");
export const buttonColor=multiplyColor(bg, dark?2:0.7, "c");;
export const buttonHighlightColor=multiplyColor(bg, dark?3:0.6, "c");
export const softColor=multiplyColor(fg, dark?0.8:1.4);
export const buttonStyle={
  fontSize:widgetFontSize, 
  fontWeight:700,
  margin:"1vmin",
  padding:"1vmin",
  borderColor:"white",
  paddingLeft:"2vmin",
  paddingRight:"2vmin",
  borderWidth:"0.3vmin",
  background:buttonColor,
  color:fg,
  borderRadius:'1vmin',
  textAlign:"center",
}
export const smallText = {
  fontSize:widgetFontSize,
};

export const pageStyle = {
  minWidth:"75vmin",
}

export const smallestText = {
  fontSize:smallestFontSize,
};

export const tableStyle={
  ...smallText,
  textAlign:"left",
}

export const popupStyle = {
  ...smallText,
  color:softColor,
  padding: "1vmin",
  textAlign:"left",
  background: popupBackground,
  borderRadius: "2vmin",
}

export const eventsWidth="75vw";

export const eventsStyle={
  ...popupStyle,
  width:eventsWidth,
  position:"fixed",
  top: "2vmin",
  left: "2vh",
}

export const horizontalStyle = {
  flexDirection:"row-center", 
  display:"flex",
  justifyContent: "center",
  alignItems: "center",
}

export const horizontalStyleRight = {
  flexDirection:"row-center", 
  display:"flex",
  justifyContent: "right",
  alignItems: "right",
}
