// Copyright (C) 2022, Rutio AB, All rights reserved

import React from 'react';

import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import { Button } from './Button';

const headerSize = "2vmin";

const CustomMarker = ({ active, position, map, title, text, timestamp, opacity }) => {
  const [refReady, setRefReady] = React.useState(false);
  let popupRef = React.useRef();

  React.useEffect(() => {
    if (refReady && active) {
      popupRef.openOn(map);
    }
  }, [active, refReady, map]);

  return (
    <Marker map={map} position={position} opacity={opacity}>
      <Popup
        ref={(r) => {
          popupRef = r;
          setRefReady(true);
        }}
      >
        <div style={{fontSize:headerSize, fontWeight:600}}>{title}</div>
        {text}
        <br/>
        {timestamp.toLocaleString()}
      </Popup>
    </Marker>
  );
};

export const TimeSpace = (props) => {
  const height = props.height?props.height:"30vmin";
  const width = props.width?props.width:"100vw";
  const top = props.top ? props.top : 0;
  const series = props.series ? props.series : [];
  const nowS = new Date().getTime()/1000;
  let ageForMaxOpacityH = 14*24;
  const maxOpacity = 1.0;
  const opacityScale = 0.8; // how much does opacity scale
  const center = props.center ? props.center : (series.length > 0 ? [series[series.length-1].lat,series[series.length-1].lng] : [0,0]);
  const zoom = props.zoom ? props.zoom : 13;
  const setZoom = props.setZoom ? props.setZoom : ()=>{};
  const expanded = props.expanded;
  const setExpanded = props.setExpanded;

  const [map, setMap] = React.useState();
  

  if ((!expanded) && setExpanded) {
    return <div><hr/><Button text="Map" onClick={()=>setExpanded(true)}/></div>
  }

  if (series.length === 0)
    return <div style={{height, width,left:0, background:"#eee"}}></div>

  const MyZoomListener = () => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoom(mapEvents.getZoom());
            console.log("New zoom level:", zoom);
      },
    });
    return null
  }

  let oldestS = series[0].timestamp.getTime()/1000;
  let newestS = oldestS;
  series.map(item => {
    let s = item.timestamp.getTime()/1000; 
    if (s<oldestS) oldestS=s;
    if (s>newestS) newestS=s;
  })

  ageForMaxOpacityH = (nowS - oldestS)/3600.0;
  if (ageForMaxOpacityH < 1.0)
    ageForMaxOpacityH = 1.0; // Do not fade 2 seconds old items so much

  return <div style={{fontSize:"3vmin", height, width, top, left:0 }}>
  {0 === series.length && "No positions yet"}
  {0 !== series.length && <MapContainer 
                            style={{height, width, left:0, align:"center"}} 
                            center={center} zoom={zoom} whenCreated={setMap}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {series.map((point, i) => {
      const ageS = point.timestamp ? nowS - point.timestamp.getTime()/1000 : 0;
      let ageH = ageS/3600.0;
      if (ageH>ageForMaxOpacityH)
        ageH = ageForMaxOpacityH;
      const agingFactor = ageH/ageForMaxOpacityH*opacityScale;
      const opacity = maxOpacity-agingFactor;

      return <CustomMarker key={i} map={map} active={point.active} opacity={opacity} 
              position={[point.lat, point.lng]} title={point.title} text={point.text} timestamp={point.timestamp}/>
      })
    }
    <MyZoomListener/>
  </MapContainer>}
  </div>
}
