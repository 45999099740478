import React from 'react';
import { smallestFontSize, smallText, horizontalStyle, softColor } from "../styles";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Help } from "../components/Help";
import { MultiRegister, Qrl } from './Register';
import { api } from "../App"
import { i18 } from '../i18n/i18';

const MAX_NUMBERS_PER_SEQUENCE = 64;

export const Create = (props) => {
  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const profiles = props.profiles;
  const [first, setFirst] = React.useState(1); 
  const [last, setLast] = React.useState(15);
  const [check, setCheck] = React.useState(false);
  const [print, setPrint] = React.useState(false);
  const [error, setError] = React.useState(null);

  const checkSequence = () => {
    let f = parseInt(first);
    let l = parseInt(last);
    if (f > 0 && l > 0 && f <= l && l-f<MAX_NUMBERS_PER_SEQUENCE)
      return true;
    return false;
  }

  // Checking
  React.useEffect(()=>{
    let cancelled = false;
    if (!(checkSequence() && check))
      return;
    let f = async () => {
      try {
        const call = api + "/check?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(key) + "&user=" + encodeURIComponent(props.user) 
            +"&first=" + encodeURIComponent(first) + "&last=" + encodeURIComponent(last);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setError(data.error ? data.error : "This range is free");
          if (data.error)
            setPrint(false);
          setCheck(false);
        }
      } catch (err) {
        (!cancelled) && props.onDone(i18(''));
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [check, first, last]);

  if (!(key && client && user)) {
    props.onDone(i18('errorMissingInformation'));
    return "...";
  }

  if (error) {
    return <div>{i18('error')}
      <hr></hr>
      <div style={smallText}>{error}</div>
      <hr></hr>
      <Button text="Ok" onClick={()=>setError(null)} />
      </div>;
  }

  const count = parseInt(last) - parseInt(first) + 1;

  if (!(checkSequence() && (check || print))) {
    return <div>{i18('registerSeveral') + " - " + i18('selectRange')}<div style={{...horizontalStyle,marginTop:"1vmin", color:softColor, fontSize:smallestFontSize}}>{i18('noteAboutApi')}<Help text="helpAPI"/></div>
      <hr/>
      <Input placeholder={i18('first')} hint={i18('firstHint')}  value={first} onChange={(e)=>setFirst(e.target.value)} type="number" />
      <Input placeholder={i18('last')}  hint={i18('lastHint')}  value={last} onChange={(e)=>setLast(e.target.value)} type="number" />
      <hr/>
      {checkSequence() && <div style={{fontSize:smallestFontSize}}>{parseInt(last)-parseInt(first)+1} serials selected</div>}
      {!checkSequence() && <div style={{fontSize:smallestFontSize}}>Select a range of 1-{MAX_NUMBERS_PER_SEQUENCE} serials, lowest first</div>}
      <hr/>
      <Button text={i18('back')} onClick={()=>props.onDone(null)}/>
      <Button disabled={!checkSequence()} text={i18('check')} onClick={()=>setCheck(true)}></Button>
      <Button disabled={!checkSequence()&&count>props.credits} text={i18('registerSeveral')} onClick={()=>{setCheck(true); setPrint(true)}}></Button>
    </div>
  }

  if (check)
    return <div>Checking that {first} to {last} is free
      <hr></hr>
      This can take up to a minute.
      <hr></hr>
      <Button text={i18('cancel')} onClick={()=>{setPrint(false);setCheck(false);}} />
      </div>;

  if (print)
    return <MultiRegister client={client} clientkey={key} user={user} profiles={profiles} first={first} last={last} 
                          credits={props.credits} setCredits={props.setCredits} onDone={(e)=>{setPrint(false); setError(e)}} />;

  return <div>
    Registerred {count} new QRs/Tags.
    <hr/>
    You now have {props.credits} credits left.
    <hr/>
    <Button onClick={()=>{props.onDone(null)}} text="Done"></Button>
  </div>;
}

export const Print = (props) => {
  const [cols, setCols] = React.useState(3);
  const [rows, setRows] = React.useState(8);
  const [view, setView] = React.useState(0);

  const [first, setFirst] = React.useState(1);

  const [size, setSize] = React.useState(Math.floor(window.innerHeight/rows*0.8));
  const [leftMargin, setLeftMargin] = React.useState(0);
  const [topMargin, setTopMargin] = React.useState(0);
  const [widthScale, setWidthScale] = React.useState(1);
  const [heightScale, setHeightScale] = React.useState(1);

  console.log("Print props: ", props);
  const count = parseInt(cols) * parseInt(rows);

  if (view === 0)
    return <div>{i18('printSeveral')} - {i18('layout')}<div style={{...horizontalStyle,marginTop:"1vmin", color:softColor, fontSize:smallestFontSize}}>{i18('noteAboutApi')}<Help text="helpAPI"/></div>
      <hr/>
      <Input key="cols" placeholder="Columns" hint="Number of columns to arrange" type="number" value={cols} onChange={(e)=>setCols(e.target.value)}></Input>
      <Input key="rows" placeholder="Rows" hint="Number of rows to arrange" type="number" value={rows} onChange={e=>setRows(e.target.value)}></Input>
      <div style={{fontSize:smallestFontSize}}>Total: {cols*rows} spaces</div>
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone(null)}/>
      <Button disabled={count < 1 || count > 100} text={i18('next')} onClick={()=>{setView(1); setSize(Math.floor(window.innerHeight/rows*0.8))}}/>
      </div>;
  if (view === 1)
    return <div>{i18('printSeveral')} - {i18('layout')}
      <hr/>
      <Input key="tm" placeholder="Top Margin" hint="Margin at top (%)" type="number" value={topMargin} onChange={e=>setTopMargin(e.target.value)}></Input>
      <Input key="lm" placeholder="Left Margin" hint="Margin on left (%)" type="number" value={leftMargin} onChange={e=>setLeftMargin(e.target.value)}></Input>
      <Input key="ht" placeholder="Code Height" hint="Height of each QR code (pts)" type="number" value={size} onChange={e=>setSize(e.target.value)}></Input>
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone(null)}/>
      <Button text={i18('back')} onClick={()=>setView(0)}/>
      <Button text={i18('next')} onClick={()=>setView(2)}/>
    </div>

  if (view === 2)
    return <div>{i18('printSeveral')} - {i18('layout')}
    <hr/>
      <Input key="hs" placeholder="Height scale" hint="Scaling of layout height" type="number" value={heightScale} onChange={e=>setHeightScale(e.target.value)}></Input>
      <Input key="ws" placeholder="Width scale" hint="Scaling of layout width" type="number" value={widthScale} onChange={e=>setWidthScale(e.target.value)}></Input>
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone(null)}/>
      <Button text={i18('back')} onClick={()=>setView(1)}/>
      <Button text={i18('next')} onClick={()=>setView(3)}/>
    </div>

if (view === 3)
  return <div>{i18('printSeveral')} - {i18('serials')}
    <hr/>
    <Input key="fst" placeholder={i18('first')} hint={i18('firstHint')} type="number" value={first} onChange={(e)=>setFirst(e.target.value)}></Input>
    <Input key="lst" placeholder={i18('last')} hint={i18('lastHint')}  type="number" value={parseInt(first)+count-1} disabled={true}></Input>
    <hr/>
    <Button text={i18('cancel')} onClick={()=>props.onDone(null)}/>
    <Button text={i18('back')} onClick={()=>setView(2)}/>
    <Button text={i18('next')} onClick={()=>setView(4)}/>
  </div>
if (view === 4) {
    let elements = [];
    let serial = parseInt(first);
    for (let row = 0; row < parseInt(rows); ++row) {
      for (let col = 0; col < parseInt(cols); ++col) {
        console.log("Placing div for row, col", row, col);
        elements.push(<div key={""+row+","+col} style={{position:"absolute", 
                                                        left:`${(((parseInt(col)*100)/cols)+parseFloat(leftMargin))*parseFloat(widthScale)}vw`, 
                                                        top:`${(parseFloat(topMargin)+(parseInt(row)*100)/rows)*parseFloat(heightScale)}vh`, 
                                                        verticalAlign:"middle", textAlign:"center"}}>
          <Qrl silent={true} client={props.client} clientkey={props.clientkey} user={props.user} height={""+size} serial={serial} onDone={()=>{}}></Qrl>
          </div>);
        serial++;
      }
    }
    return <page size="dymo13x25"><div style={{position:"fixed", top:0, left:0, width:"100vw", height:"100vh", background:"#fff", color:"#000"}} onClick={()=>setView(3)}>
      {elements}
    </div></page>;
  }

  return <div>Something went wrong
      <hr/>
      <Button text={i18('back')} onClick={()=>props.onDone(null)}/>
    </div>;
}
