// Copyright (C) 2022, Rutio AB, All rights reserved

import React from 'react';
import './App.css';
import {fg, bg, popupStyle, coverStyle, widgetFontSize, popupBackground, smallestFontSize, horizontalStyle, smallText} from './styles';
import {Button, CloseButton, MenuButton} from './components/Button';
import {Input} from './components/Input';
import { TermsAndConditions } from './components/TermsAndConditions';
import {NewClient} from './views/NewClient';
import {Register, Qrl} from './views/Register';
import {Claim} from './views/Claim';
import {Users} from './views/Users';
import {Orders} from './views/Orders';
import {Datas} from './views/Datas';
import {Profiles} from './views/Profiles';
import {Print, Create} from './views/Registrator';
import { Supplier} from './views/Supplier';
import { Checkin, List } from './views/Checkin';
import { AccessLog } from './views/AccessLog';
import { Help } from './components/Help';
import {i18, i18ChangeLanguage} from './i18n/i18';

const isDebug = () => {
  return window.location.host === "localhost:3000";
}

//const api = "http://192.168.20.212:37684"
//export const api = window.location.href.startsWith("http://localhost:3000") ? "http://localhost:37684" : "https://www.qrlio.com/qrl-api";

export const api = isDebug() ? "http://localhost:37684/qrl-api" : ("https://" + window.location.host + "/qrl-api");

console.log("Location:" + window.location.href);
console.log("Using API:" + api);

const Login = (props) => {
  const [state, setState] = React.useState(null);
  const [user, setUser] = React.useState(props.user);
  const [pass, setPass] = React.useState("");

  React.useEffect(()=>{
    let cancelled = false;
    if (state !== "login") 
      return;
    if (!(user && pass))
      return;
    const f = async () => {
      try {
        const call = api + '/login?user=' + encodeURI(user) + "&pass=" + encodeURI(pass);
        const response = await fetch(call);
        const {client, key, profiles, admin, credits, style} = await response.json();
        if (cancelled)
          return;
        if (key) {
          setState("ok");
          props.onDone(client, user, key, profiles, admin, credits, style);
        }
        else
          setState("failed");
      } catch (err) {
        if (cancelled)
          return;
        setState("failed");
      }
    }
    f();
    return ()=>{cancelled = true; }
  }, [state, user, pass, props])

  if (state === null)
    return <div>
      {i18('login')}
      <hr/>
        <Input placeholder={i18('username')} type="email" onChange={(e)=>setUser(e.target.value.toLowerCase())} value={user}/>
        <Input placeholder={i18("password")} type="password" onChange={(e)=>setPass(e.target.value)} value={pass}/>
      <hr/>
        <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
        <Button onClick={()=>setState("login")} text={i18('login')} disabled={!(user && user.length>0 && pass && pass.length>0)}/>
        {<TermsAndConditions/>}
      </div>;
  else if (state === "login")
    return <div>
      {i18('processing')}
    </div>;
  else if (state === "ok")
    return <div>
      {i18('preparing')}
    </div>;
  else if (state === "failed")
    return <div>
      {i18('loginFailed')}
      <hr/>
      <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
      <Button onClick={()=>setState(null)} text={i18('back')}/>
    </div>
}

const App = () => {
  const search = window.location.search;
  let params = {}
  search.split("&").map(s => {
    if (s.startsWith('?')) s = s.substring(1);
    const p = s.split("=");
    if (p && p.length === 2)
      params[p[0]] = decodeURIComponent(p[1]);
    return 0;
  });

  const clientInitial = window.localStorage.getItem("client") ? window.localStorage.getItem("client") : (params.hasOwnProperty('client') ? params.client : null);
  const serialInitial = params.hasOwnProperty('serial') ? params.serial : "";
  const hashInitial = params.hasOwnProperty('hash') ? params.hash : null;
  const keyInitial = window.localStorage.getItem("key");
  const userInitial = window.localStorage.getItem("user");
  const adminInitial = window.localStorage.getItem("admin");
  const creditsInitial = window.localStorage.getItem("credits");
  const styleInitialJSON = window.localStorage.getItem("style");
  let styleInitial = null;
  if (styleInitialJSON) {
    try {
      styleInitial =  JSON.parse(styleInitialJSON);
    } catch (e) {}
  }

  const [client, setClient] = React.useState(clientInitial);
  const [user, setUser] = React.useState(userInitial);
  const [admin, setAdmin] = React.useState(adminInitial);
  const [credits, setCredits] = React.useState(creditsInitial);
  const [key, setKey] = React.useState(keyInitial);
  const [serial, setSerial] = React.useState(serialInitial);
  const [view, setView] = React.useState(((serialInitial && clientInitial) || hashInitial) ? "checkin" : "default");
  const [error, _setError] = React.useState(null);
  const [errorCode, setErrorCode] = React.useState(null);
  const [hash, setHash] = React.useState(null);
  const [style, setStyle] = React.useState(styleInitial);
  const [completions, setCompletions] = React.useState(null);
  const [completeText, setCompleteText] = React.useState("");
  const [refreshCompletionsTime, setRefreshCompletionsTime] = React.useState(0);
  const [showMenu, setShowMenu] = React.useState(false);

  const setError = (e, ec) => {
    _setError(e);
    if (e)
      setErrorCode(ec);
    else
      setErrorCode(null);
  }

  let storedProfiles = window.localStorage.getItem("profiles");
  try {
    storedProfiles = storedProfiles ? JSON.parse(storedProfiles) : [];
  } catch (e) { storedProfiles = []};
  const [profiles, setProfiles] = React.useState(storedProfiles);
  
  const mayRegister = () => {
    return admin && admin.includes("R");
  }
  const mayAdmin = () => {
    return admin && admin.includes("A");
  }
  const mayList = () => {
    return admin && admin.includes("L");
  }

  const complete = (text) => {
    setCompleteText(text);
  }

  React.useEffect(()=>{
    let ignore = false;
    console.log("Trying to complete '" + completeText + "'");
    let func = completeText ? "completions" : "recents";
    const call = api + "/"+func+"?client=" + encodeURIComponent(client) 
    + "&key=" + encodeURIComponent(key) 
    + "&user=" + encodeURIComponent(user)
    + "&match=" + encodeURIComponent(completeText);
    fetch(call)
      .then(response => response.json())
      .then(data => {if(!ignore){setCompletions(data);}});
    return () => {ignore=true;}
  }, [completeText, refreshCompletionsTime]);

  React.useEffect(()=>{
    let handle;
    if (!completeText)
      handle = setTimeout(()=>{setRefreshCompletionsTime(refreshCompletionsTime+1)}, 7000);
    return ()=>{if (handle) clearTimeout(handle);};
  }, [completeText, refreshCompletionsTime])

  const serialInCompletions = () => {
    if (!completions || completions.length === 0)
      return false;
    return (completions[0] === serial);
  }

  const genRegister = () => {
    if (!(mayList()||mayRegister())) {
      return <div>
        {i18('scan')} {client ? client : ""}
      </div>
    }
    const enableView = mayList() && (!(!serial || serial.length == 0 || !serialInCompletions()));
    const enableCreate = mayRegister() && !(!serial || serial.length == 0 || serialInCompletions());
    return (<div>
      <div style={{...horizontalStyle}}>{i18('enterSerial')}<Help text="helpSerial"/></div>
      <hr/>
      <Input placeholder={i18('serial')} type="serial" hint={i18('serialHint')} onChange={(e)=>{console.log(e.message); setSerial(e.target.value);}} 
        value={serial} completions={completions} complete={complete}/>
      <hr/>
      {enableView ? <Button onClick={()=>setView("checkin")} text={i18('checkIn')} /> : null}
      {enableView ? <Button onClick={()=>setView("qrl")} text={i18('showSerialQR')} /> : null}
      {(enableCreate && key) ? <Button disabled={credits<=0 || !serial || serial.length == 0 || serialInCompletions()} onClick={()=>setView("registering")} text={i18('registerSerial')}/> : null}
      </div>);
  }

  const genDefault = () => {
    if (key && client && profiles) {
      console.log("Default -> Register");
      setView('register');
      return <div>...</div>;
    }
    return <div>
      <img style={{width:"15vmin", height:"15vmin", marginRight:"1vmin"}} src="logo192.png"/>
      <div style={{fontSize:"6vmin", fontFamily:"fixed", fontStyle:"italic", fontWeight:600}}>{i18('appname')}</div>
      <hr></hr>
      <Button onClick={()=>{setView("login")}} text={i18('login')}/>
      <hr></hr>
      <div style={{fontSize:"2.5vmin", fontFamily:"fixed", fontStyle:"italic"}}>{i18('slogan')}</div>
    </div>
  }

  const genLogin = () => {
    return <Login client={client} onDone={(client, user, key, profiles, admin, credits, style)=>{
      if (key && user && client && profiles) {
        setKey(key); setUser(user); setClient(client); setProfiles(profiles); setAdmin(admin); setCredits(credits); setStyle(style);
        window.localStorage.setItem("key", key);
        window.localStorage.setItem("user", user);
        window.localStorage.setItem("client", client);
        window.localStorage.setItem("admin", admin);
        window.localStorage.setItem("credits", credits);
        window.localStorage.setItem("profiles", JSON.stringify(profiles));
        window.localStorage.setItem("style", JSON.stringify(style));
        setView('register')
      } else {
        setView('default');
      }
    }}/>;
  }

  const genError = () => {
    return <div>{i18('error')}
      <hr></hr>
      <div style={{fontSize:widgetFontSize}}>{error}</div>
      <div style={{fontSize:smallestFontSize}}>{errorCode}</div>
      <hr></hr>      
        <br></br>
        <Button onClick={()=>{setError(null); setView((key && client) ? "register" : "default")}} text={i18('back')}/>
      </div>;
  }

  const genClaim = () => {
    if (!mayRegister()) {
      setError(i18('errorCannotRegister'));
      setView(error);
      return;
    }
    else if (user && key && (key.length > 0)) {
      return <Claim serial={serial} setSerial={setSerial} onDone={(e) => {
        if (e) {
          setError(e);
          setView('error');
        }
        else {
          setHash(params.hash);
          setView('registering');
        }
      }
      }/>;
    }
    else {
      setError("Login required");
      setView("error");
    }
  }

  const genRegistering = () => {
    if ((serial.length > 0) && user && key && (key.length > 0))
      return <Register serial={serial} client={client} profiles={profiles} clientkey={key} user={user} hash={hash} onDone={(e, cred) => {
        setError(e);
        setHash(null); // Do forget the hash which we possibly registerred for
        if (cred) {
          setCredits(cred);
          window.localStorage.setItem("credits", cred);
        }
        if (e)
          setView('error');
        else {
          setView('qrl');
        }}}/>; 
    else {
      if (!serial.length)
        setError("Please enter serial number");
      if ((!user) || (!key) || key.length===0)
        setError("Login required");
      setView("error");
    }
  }

  const genQrl = () => {
    return <Qrl client={client} clientkey={key} serial={serial} user={user} onDone={(e)=>{
      setError(e);
      setView (e ? "error" : ((key && profiles) ? "register" : "default"));
    }}></Qrl>
  }

  const genCheckin = () => {
    return <Checkin client={client} serial={serial} user={user} hash={params.hash} clientkey={key} onDone={(e)=>{
      setError(e);
      if (e==="claim")
        setView("claim")
      else
        setView(e ? "error" : "default");
    }}/>;
  }

  const genUsers = () => {
    return <Users client={client} clientkey={key} user={user} onDone={(e)=>{
      setError(e);
      setView(e ? "error" : "default");
    }}/>;
  }

  const genDatas = () => {
    return <Datas client={client} clientkey={key} user={user} onDone={(e)=>{
      setError(e);
      setView(e ? "error" : "default");
    }}/>;
  }

  const genProfiles = () => {
    return <Profiles client={client} clientkey={key} user={user} onDone={(e, p)=>{
      setError(e);
      if (p) { 
        // Update profiles for user
        console.log("onDone: profiles:", p);
        setProfiles(p); 
        window.localStorage.setItem("profiles", JSON.stringify(p)); 
      }
      setView(e ? "error" : "default");
    }}/>;
  }

  const genOrders = () => {
    return <Orders client={client} clientkey={key} user={user} credits={credits} setCredits={setCredits} onDone={(e)=>{
      setError(e);
      setView(e ? "error" : "default");
    }} />
  }

  const genCreate = () => {
    return <Create client={client} clientkey={key} user={user} credits={credits} setCredits={setCredits}  profiles={profiles} onDone={(e, cred)=>{
      console.log("Create done, error:", e, " credits: ", cred);
      setError(e);
      setView(e ? "error" : "default");
    }} />
  }

  const genPrint = () => {
    return <Print client={client} clientkey={key} user={user} onDone={(e, cred)=>{
      setError(e);
      setView(e ? "error" : "default");
    }} />
  }

  const genList = () => {
    return <List client={client} clientkey={key} user={user} serial={serial} key={key} onDone={(e)=>{
      setError(e);
      setView(e ? "error" : "default");
    }} />
  }


  const genNewClient = () => {
    return <NewClient onDone={()=>setView("default")}/>
  }

  const genSupplier = () => {
    return <Supplier client={client} clientkey={key} user={user} onDone={()=>setView("default")} />    
  }

  const genAccessLog = () => {
    return <AccessLog client={client} clientkey={key} user={user} onDone={()=>setView("default")} />    
  }

  const views = {
    'default' : genDefault,
    'login' : genLogin,
    'error' : genError,
    'qrl' : genQrl,
    'checkin' : genCheckin,
    'register' : genRegister,
    'registering' : genRegistering,
    'claim' : genClaim,
    'users' : genUsers,
    'datas' : genDatas,
    'profiles' : genProfiles,
    'orders' : genOrders,
    'newclient' : genNewClient,
    'create' : genCreate,
    'print' : genPrint,
    'list' : genList,
    'supplier' : genSupplier,
    'accesslog' : genAccessLog,
  };

  const getDebugInfo = () => {
    if (!isDebug())
      return null;
    return null;
    return <div style={{fontSize:"2vmin", color:"#8888", position:"fixed", bottom:10}}>
      HOST: {window.location.host}
      <br></br>
      VIEW: {view}
      <br></br>
      SERIAL: {serial}
      <br></br>
      CLIENT: {client}
      <br></br>
      USER: {user + " (" + admin + ") - Credits: " + credits}
      <br></br>
      HASH: {params.hash}
      <br></br>
      KEY: {key}
    </div>
  }

  const onLogout = () => {
    if (key && client) {
      setKey(null);
      setClient("");
      setSerial("");
      setUser("");
      setProfiles([]);
      setAdmin("");
      window.localStorage.removeItem("client");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("key");
      window.localStorage.removeItem("profiles");
      window.localStorage.removeItem("admin");
      setView("default");
    } else {
      setView("login");
    }
  }

  const UserMenu = (props) => {
    let {show, setShow} = props;
    let [pingCount, setPingCount] = React.useState(0);

    // Autologout pimg
    React.useEffect(()=>{
      let cancelled = false;
      if (!(user && client && key))
        return;
      if (!pingCount)
        return;
      const f = async () => {
        try {
          const call = api + '/ping?user=' + encodeURI(user) + "&client=" + encodeURI(client) + "&key=" + encodeURI(key);
          const response = await fetch(call);
          const ok = await response.json();
          if (cancelled)
            return;
          if (!ok)
            props.onLogout();
          console.log("Ping ", pingCount ,"- result:", ok);
        } catch (err) {
          console.log("Error in ping - logging out");
          if (cancelled)
            return;
          props.onLogout();
        }
      }
      f();
      return ()=>{cancelled = true; }
    }, [pingCount, user, client, key])

    // Autologout timer
    React.useEffect(() => {
      const onTimer = () => {
        if (pingCount > 6*12)
          props.onLogout(); // Autologout UI after 12 hrs

        setPingCount(()=>pingCount+1);
      }
      const timer = setInterval(onTimer, 10*60*1000);
      return ()=>clearInterval(timer);
    }, [pingCount]);
  
    if (!show) {
      return <div onClick={()=>setShow(true)} style={{
        borderRadius:"2vmin", background:popupBackground, width:"10vmin", 
        height:"10vmin", borderColor:"white", position:"fixed", right:"2vw", top:"2vh"}}>
          <div style={{position:"absolute", top: "2.5vmin", right:"2vmin", background:"#eee", width:"6vmin", height:"1.1vmin", borderRadius:"1vmin"}} />
          <div style={{position:"absolute", top: "4.5vmin", right:"2vmin", background:"#eee", width:"6vmin", height:"1.1vmin", borderRadius:"1vmin"}} />
          <div style={{position:"absolute", top: "6.5vmin", right:"2vmin", background:"#eee", width:"6vmin", height:"1.1vmin", borderRadius:"1vmin"}} />        
        </div>
    }
    return <div key="menudiv" style={coverStyle} onClick={()=>{setShow(false)}}>
      <div style={{...popupStyle, position:"fixed", flexDirection:"row", right:"2vw", top:"2vh"}} >
      <table><tbody>
        <tr><td>{i18('organization')}</td><th>{client}</th><td style={{width:widgetFontSize}}></td></tr>
        <tr><td>{i18('user')}</td><th>{user}</th></tr>
      {props.mayRegister() && <tr><td>{i18('credits')}</td><th>{credits} {i18('serials')}</th></tr>}
      </tbody></table>
      <hr></hr>
      {mayAdmin() && <div>{i18('administratorMenu')}<br/></div>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('profiles')} text={(profiles && profiles.length===1) ? profiles[0].name : i18('profiles')}/>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('datas')} text={i18('data')}/>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('users')} text={i18('users')}/>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('orders')} text={i18('orders')}/>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('supplier')} text={i18('supplier')}/>}
      {mayAdmin() && <MenuButton onClick={()=>props.setView('accesslog')} text={i18('accessLog')}/>}
      {mayAdmin() && <hr/>}
      {mayRegister() && <div>{i18('registratorMenu')}</div>}
      {mayRegister() && <MenuButton onClick={()=>props.setView('create')} text={i18('registerSeveral')}/>}
      {mayRegister() && <MenuButton onClick={()=>props.setView('print')} text={i18('printSeveral')}/>}
      {mayRegister() && <hr/>}
      {mayList() && <MenuButton onClick={()=>props.setView('list')} text={i18('list')}/>}
      {/*isDebug() &&*/ <MenuButton onClick={()=>{i18ChangeLanguage(); setError(i18('language'))}} text={i18('language')}/>}
      <MenuButton onClick={()=>props.onLogout()} text={i18('logout')}/>
      <CloseButton/>
      </div></div>}

  if (view === "print")
    return <div className="App" style={{background:bg, color:fg, fontSize:widgetFontSize}}>
      <header className="App-header">
        { views.hasOwnProperty(view) ? views[view]() : genError()}
      </header>
    </div>;

  let background = style && style.background ? style.background : bg;
  let color = style && style.color ? style.color : fg;

  return (
    <div key="top" className="App" style={{background, color, fontSize:widgetFontSize}}>
      <header className="App-header">
        {getDebugInfo()}
        { view==="default" && <div style={{ position:'fixed', bottom:"2vmin", left:"2vmin", color:"#555", fontSize:smallestFontSize, fontFamily:"Arial"}} onClick={()=>window.open("https://www.rutio.se")}>Copyright (C) 2022, Rutio AB</div>}
        { (view==="default" || view==="checkin") && <div style={{ position:'fixed', bottom:"2vmin", right:"2vmin", color:"#555", fontSize:smallestFontSize, fontFamily:"Arial"}}><TermsAndConditions short={true}/></div>}
        { view==="default" && <div style={{position:"fixed", top:"2vmin", right:"2vmin"}}><Button text={i18('registerAccount')} onClick={()=>setView("newclient")}/></div>}
        { views.hasOwnProperty(view) ? <div>{views[view]()}</div> : genError()}
        { user && view !== "login" && view !== "default" && view !== "list" && <UserMenu key="menu" show={showMenu} setShow={setShowMenu} onLogout={onLogout} setView={setView} mayRegister={mayRegister} mayAdmin={mayAdmin}/> }
      </header>
    </div>
  );
}

export default App;
