import React from 'react';
import {horizontalStyle} from '../styles';
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Help} from "../components/Help";
import { i18 } from '../i18n/i18';

export const Claim = (props) => {
  const [serial, setSerial] = React.useState(props.serial);

  return <div><div style={{...horizontalStyle}}>{i18('claimSerial')}<Help text="helpSerial"/></div>
    <hr/>
    <Input placeholder={i18('enterSerial')} type="serial" hint={i18('serialHint')} onChange={(e)=>{console.log(e.message); setSerial(e.target.value);}} value={serial}/>
    <hr/>
    <Button text={i18('cancel')} onClick={()=>props.onDone(i18('cancelled'))}></Button>
    <Button text={i18('next')} onClick={()=>{props.setSerial(serial); props.onDone(null)}}></Button>
    </div>;
}

