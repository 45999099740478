import React from 'react';
import { buttonStyle, tableStyle, smallestFontSize, inputStyle, smallestText } from "../styles";
import { Input, invalid } from "../components/Input";
import { Button } from "../components/Button";
import { api } from "../App"
import { i18 } from '../i18n/i18';

export const Supplier = (props) => {
  const key = props.clientkey;
  const user = props.user;
  const client = props.client;
  const [loaded, setLoaded] = React.useState(false);
  const [supplier, setSupplier] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [submit, setSubmit] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [adapter, setAdapter] = React.useState("local");
  const [local, setLocal] = React.useState(true);
  const [modified, setModified] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  React.useEffect(()=>{
    let cancelled = false;
    if (loaded)
      return ()=>cancelled=true;
    const f = async () => {
      try {
        const call = api + "/supplier?client=" + encodeURIComponent(client) + 
          "&user=" + encodeURIComponent(user) +
          "&key=" + encodeURIComponent(key) ;
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const data = await response.json();
        console.log("Update supplier result:", data);
        if (!cancelled) {
          setLocal(data.local);
          setAdapter(data.adapter);
          setSupplier(data.supplier);
          setUsername(data.username);
          setPassword(data.password);
          setStatus(data.status);
          setLoaded(true);
          setModified(false);
        }
      } catch (err) {
        console.log("Failed get supplier information", err);
        (!cancelled) && setError("Failed to get supplier information: " + err.message);
        (!cancelled) && setSubmit(false) && setLoaded(false);
      }
    }
    setSubmit(false);
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  React.useEffect(()=>{
    let cancelled = false;
    if (!loaded) // Weird case
      return ()=>cancelled=true; // Do nothing
    if (!submit)
      return ()=>cancelled=true;
    const f = async () => {
      try {
        const call = api + "/supplier?client=" + encodeURIComponent(client) + 
          "&user=" + encodeURIComponent(user) +
          "&key=" + encodeURIComponent(key) + 
          "&supplier=" + encodeURIComponent(supplier) +
          "&password=" + encodeURIComponent(password) +
          "&username=" + encodeURIComponent(username);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const data = await response.json();
        console.log("Supplier result:", data);
        if (!cancelled) {
          setLocal(data.local);
          setAdapter(data.adapter);
          setSupplier(data.supplier);
          setUsername(data.username);
          setPassword(data.password);
          setStatus(data.status);
          setError(data.error);
          setModified(false);
          setSubmit(false)
        }
      } catch (err) {
        console.log("Supplier information update failed", err);
        (!cancelled) && setError("Supplier information update failed: " + err.message);
        (!cancelled) && setSubmit(false);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  if (error)
    return <div>
      {error}
      <hr></hr>
      <Button onClick={()=>{setError(null)}} text={i18('back')}/>
    </div>;

  if (!loaded) {
    return <div>
      {i18('loading')}
      <hr/>
      <Button text={i18('cancel')} onClick={()=>props.onDone()}/>
    </div>
  }

  if (submit)
    return <div>
      {i18('processing')}
      <hr/>
      <Button text={i18('cancel')} onClick={()=>setSubmit(false)}/>
    </div>

  if (confirm) 
    return <div>
      {i18('warning')}
      <hr/>
      {i18('supplierChangeWarning')}
      <hr/>
      <Button text={i18('cancel')} onClick={()=>setConfirm(false)}/>
      <Button text={i18('submit')} onClick={()=>{setConfirm(false); setSubmit(true)}}/>
    </div>

  if (local)
    return <div>
    {i18('qrlio')}
    <hr/>
      {i18('status') + ": " + status}
    <hr/>
    <Button text={i18('done')} onClick={()=>props.onDone()}/>
    </div>

  return <div>
    {adapter ? i18(adapter) : i18('supplier')}
    <br/>
    <br/>
    <div style={smallestText}>{i18('status') + ": " + status}</div>
    <hr/>
    <Input key={1} type="server" placeholder={i18('server')} hint={adapter + " " +i18('server')} value={supplier} onChange={(e)=>{setSupplier(e.target.value); setModified(true);}}/>
    <Input key={2} type="email" placeholder={i18('username')} hint={adapter + " " +i18('username')} value={username} onChange={(e)=>{setUsername(e.target.value); setModified(true);}}/>
    <Input key={3} type="password" placeholder={i18('password')} hint={adapter + " " +i18('password')} value={password} onChange={(e)=>{setPassword(e.target.value); setModified(true);}}/>
    <hr/>
    <Button text={i18('done')} onClick={()=>props.onDone()}/>
    <Button text={i18('submit')} onClick={()=>setConfirm(true)} disabled={(!modified) || invalid("server", supplier) || invalid("email", username) || invalid("password", password)}/>
  </div>
}

