import React from 'react';
import { buttonStyle, tableStyle, smallestFontSize, inputStyle, horizontalStyle } from "../styles";
import { Input, validate } from "../components/Input";
import { Button } from "../components/Button";
import { Help } from '../components/Help';
import { api } from "../App"
import { i18 } from '../i18n/i18';

const AddData = (props) => {
  const key = props.clientkey;
  const create = props.data ? false : true; // If no data was supplised, we are to create a new one
  const [name, setName] = React.useState(props.data ? props.data.name : "");
  const [value, setValue] = React.useState(props.data ? props.data.value : "");

  const [submit, setSubmit] = React.useState(false);
  const [removeConfirm, setRemoveConfirm] = React.useState(0);
  const [error, setError] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    if (!submit)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/adddata?client=" + encodeURIComponent(props.client) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&key=" + encodeURIComponent(key) + 
          "&name=" + encodeURIComponent(name) + 
          "&value=" + encodeURIComponent(value) + 
          "&create=" + encodeURIComponent(create ? "true" : "false");
        const response = await fetch(call);
        const ok = await response.json();
        if (!cancelled) {
          if (!ok) {
            setError(i18('errorSubmitFailed'));
            setSubmit(false);
          }
          else 
            props.onDone(i18('submitting') + " " + name);
        }
      } catch (err) {
        (!cancelled) && setError(i18('errorSubmitFailed'));
        (!cancelled) && setSubmit(false);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [submit]);

  // User deletion
  React.useEffect(()=>{
    let cancelled = false;
    if (removeConfirm !== 2)
      return; // Do nothing
    const f = async () => {
      try {
        const call = api + "/deletedata?client=" + encodeURIComponent(props.client) + 
          "&key=" + encodeURIComponent(key) + 
          "&user=" + encodeURIComponent(props.currentuser) +
          "&name=" + encodeURIComponent(name);
        console.log("Calling " + call);
        const response = await fetch(call);
        console.log("returned:", response);
        const ok = await response.json();
        console.log("remove data result:", ok);
        if (!cancelled) {
          if (!ok) {
            setError("Failed to delete data " + name);
            setSubmit(false);
          }
          else 
            props.onDone(i18('deleted') + " " + name);
        }
      } catch (err) {
        (!cancelled) && setError(i18('failed'));
        (!cancelled) && setRemoveConfirm(0);
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [removeConfirm]);

  if (error)
    return <div>
      {error}
      <hr></hr>
      <Button onClick={()=>{setError(false); setRemoveConfirm(0);}} text={i18('back')}/>
    </div>;

  if (removeConfirm === 1) {
    return <div>{i18('delete')} {name}?<hr/>
            <Button text={i18('cancel')} onClick={()=>setRemoveConfirm(0)}></Button>
            <Button text={i18('delete')} onClick={()=>setRemoveConfirm(2)}></Button></div>
  }

  if (!submit && (removeConfirm===0)) {
    return <div>
      <div style={horizontalStyle}>
      {create ? i18('add') : i18('edit')} {i18('onedata')}
      <Help text='helpData'/>
      </div>
      <hr/>
      <Input maxLength="44" type="attribute" hint={i18('nameHint')} disabled={!create} placeholder={i18('name')} style={inputStyle} value={name} onChange={(e)=>setName(e.target.value)}/>
      <Input maxLength="800" type="value" placeholder={i18('value')} hint={`{${name}} `+i18('willBeReplacedByThis')} value={value} onChange={(e)=>setValue(e.target.value)}/>
      <hr></hr>
      <Button onClick={()=>props.onDone(null)} text={i18('cancel')}/>
      <Button onClick={()=>setRemoveConfirm(1)} text={i18('delete')}/>
      <Button onClick={()=>setSubmit(true)} disabled={!validate("value", value) || !validate("attribute", name)} text={i18('submit')}/>
    </div>
  } else {
    if (removeConfirm===2)
      return "Deleting data...";
    return "Creating data...";
  }
}
  
export const Datas = (props) => {
  const key = props.clientkey;
  const client = props.client;
  const user = props.user;
  const [datas, setDatas] = React.useState(null);
  const [addData, setAddData] = React.useState(null);
  const [editData, setEditData] = React.useState(null);

  React.useEffect(()=>{
    let cancelled = false;
    let f = async () => {
      try {
        const call = api + "/datas?client=" + encodeURIComponent(props.client) + "&key=" + encodeURIComponent(key) + "&user=" + encodeURIComponent(props.user);
        const response = await fetch(call);
        const data = await response.json();
        if (!cancelled) {
          setDatas(data);
        }
      } catch (err) {
        console.log("Failed data retreival", err);
        (!cancelled) && props.onDone("Failed to load client data.");
      }
    }
    f();
    return ()=>{cancelled=true; }
  }, [addData, editData]);

  if (!(key && client)) {
    props.onDone(i18('errorMissingInformation'));
    return "...";
  }

  if (addData) 
    return <AddData client={client} currentuser={user} clientkey={key} onDone={()=>setAddData(false)}/>;

  if (editData)
    return <AddData client={client} currentuser={user} clientkey={key} data={editData} onDone={()=>setEditData(null)}/>

  if (!datas)
    return <div>{i18('loading')}<hr/><Button onClick={()=>props.onDone(null)} text={i18('cancel')}></Button></div>

  return <div>
    <div style={horizontalStyle}>{i18('dataFor')} {client}<Help text="helpDatas"/></div>
    <hr/>
    {datas && <div><div style={{maxHeight:"60vh", overflow:"auto"}}><table style={{...tableStyle, borderSpacing:"1vmin", fontSize:smallestFontSize}}>
      <thead>
        <th>{i18('name')}</th>
        <th>{i18('value')}</th>
        <th></th>
      </thead>
      <tbody>
        {datas.map((d) => 
          <tr key={d.name}>
          <td>{d.name}</td>
          <td>{d.value}</td>
          <td><button style={{...buttonStyle, borderRadius:"1vmin", margin:2, padding:3, fontSize:smallestFontSize}} onClick={()=>setEditData(d)}>{i18('edit')}</button></td></tr>)}
      </tbody>
    </table></div><hr/></div>}
    <Button onClick={()=>{setAddData(true)}} text={i18('add')}></Button>
    <Button onClick={()=>{props.onDone(null)}} text={i18('done')}></Button>
  </div>;
}
