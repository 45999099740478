import React from 'react';
import { Button, CloseButton } from "../components/Button";
import { eventsWidth, eventsStyle, coverStyle, softColor } from '../styles';
import { i18 } from '../i18n/i18';
import { TimeSpace } from '../components/TimeSpace';

export const ShowLog = (props) => {
  const [showLog, setShowLog] = React.useState(false);
  const [center, setCenter] = React.useState(null);
  const [n, setN] = React.useState(0);
  const [zoom, setZoom] = React.useState(12);
  const [active, setActive] = React.useState(null);
  const serial = props.serial;
  const events = props.events;
  const setHideMap = props.setHideMap ? props.setHideMap : ()=>{};
  if (!serial || !events || 0 === events.length)
    return <div>-</div>

  const onCopyLogCsv = () => {
    let csv = "";
    if (events)
      events.map(e=>{csv+=e.timestamp + ";"+ e.action + ";" + e.lat + "," + e.lng + ";" + e.value + "\n"});
    navigator.clipboard.writeText(csv);    
  }

  const MAX_EVENTS = 100;

  const mkEventTR = (e) => {
    let action = e.action;
    if (e.lat && e.lng) {
        action = <div style={{color:softColor}} 
                      onMouseEnter={()=>{/*setN(n+1);setActive(e); setCenter([e.lat, e.lng])*/}}  
                      onClick={()=>{setN(n+1);setActive(e);setCenter([e.lat, e.lng])}}>{e.action} </div>
    }
    return <tr key={e.id}><td>{e.timestamp.substring(0,10).replace("T"," ")}</td><th>{action}</th></tr>;
  }

  let series = [];
  const logMap = events.map((e) => {
    if (e.lat && e.lng) {
      series.push({lat:e.lat, lng:e.lng, timestamp:new Date(e.timestamp), title:i18('serial')+" "+e.serial, text:e.action, active:e==active});
    }
  })

  return <div style={{zOrder:2}}>
  {props.detached ? 
    <div style={{position:'fixed', top:2, left:2}}><Button small={props.small} onClick={()=>{setShowLog(true); setHideMap(true)}} text={props.small?i18('log'): (i18('show') + " " + i18('log'))}/></div>:
    <Button small={props.small} onClick={()=>{setShowLog(true); setHideMap(true)}} text={props.small?i18('log'): (i18('show') + " " + i18('log'))}/> }
  {showLog && <div style={coverStyle} >
      <div style={eventsStyle} >{i18('logFor') } {serial} 
        <TimeSpace width={eventsWidth} height="40vh" key={n} series={series} center={center} zoom={zoom} setZoom={setZoom}/>
        <div style={{maxHeight:"40vh", overflow:"auto"}} onClick={()=>{}}>
        <table>
          <tbody>
            {events.length>MAX_EVENTS && mkEventTR(events[0], 0)}
            {events.length>MAX_EVENTS && mkEventTR(events[1], 1)}
            {events.length>MAX_EVENTS && <tr><td>...</td></tr>}
            {events.map((e,i)=>i>=events.length+2-MAX_EVENTS ? mkEventTR(e,i):null)}
          </tbody>
        </table>
        </div>
        <Button text={i18('copyAsCSV')} onClick={()=>onCopyLogCsv()} />
        <CloseButton onClick={()=>{setHideMap(false); setShowLog(false)}}/>
      </div>
    </div>}
  </div>
}
