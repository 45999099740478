export const sv = {
  // Allmänt
  language: 'Svenska',
  slogan: 'Smarta lösningar för spårning via QR och NFC',
  appname: 'Qrlio',

  // Skapa konto
  registerAccount: 'Skapa Konto',
  organization: 'Organisation',
  welcome: 'Välkommen',
  whatKind: 'Vilken sorts sak vill ni hålla reda på?',
  nameYourThing: 'Ett substantiv i singular',
  thingHint: 'Paket, Installation, Sensor, Fordon, Planta, ...',
  generalInformation: 'Allmänt',
  contactInformation: 'Kontakt',
  billingInformation: 'Fakturering',
  storageOptions: 'Datalagring',
  adminInformation: 'Administratörskonto',
  organizationNameHint: "Organisationens formella namn",
  countryCode: "Landskod",
  countryCodeHint: "2 bokstävers landskod, t.ex. SE för Sverige",
  contactEmail: "E-mail",
  contactPhone: "Telefonnummer",
  website: "Hemsida",
  contactEmailHint: "Kontaktinformation för kommersiella eller tekniska samtal",
  contactPhoneHint: "Frivilligt",
  websiteHint: "Organisationens hemsida",
  billingOrgId: "Organisationsnummer",
  billingOrgIdHint: "Endast för juridiska personer",
  billingEmail: "Email för fakturor",
  billingEmailHint: "Vi skickar fakturor till denna email",
  preferredCurrency: "Er föredragna valuta",
  preferredCurrencyHint: "T.ex SEK för Svenska kronor",
  billingReference: "Fakturareferens",
  billingReferenceHint: "Er referens, t.ex. avtalsnummer",
  supplier: "Dataleverantör",
  usingContactEmail: "Vi använder din kontaktemailadress som användarnamn",
  createPasswordHint: "Välj ett lösenord för din inloggning på qrlio.com",
  confirmPassword: "Bekräfta lösenord",
  confirmPasswordHint: "Skriv in samma lösenord igen för att bekräfta",
  acceptTermsAndConditions: "Bekräfta villkoren (Terms and Conditions)",
  accountCreated: "Ditt konto har skapats. Logga in med de uppgifter du angav innan",
  server: "Server",

  odoo: 'ODOO - Till exempel från odoo.com',
  yggio3: 'Yggio 3 - Från www.sensative.com',
  qrlio: 'Qrlio.com - Våra servers i Sverige',
  custom: 'Custom - Kontakta info@qrlio.se för detaljer',
  
  // Inloggning
  login: "Logga in",
  username: "Användarnamn (Email)",
  password: "Lösenord",
  loginFailed: 'Inloggningen misslyckades, kontrollera användarnamn och lösenord',

  // Defaultvy
  enterSerial: "Skriv in ett serienummer",
  serial: 'Serienummer',
  serials: 'Serienummer',
  serialHint: 'Ett unikt nummer för ditt objekt',
  registerSerial: 'Skapa',
  checkIn: 'Checka In',
  showSerialQR: 'Visa QR',
  scan: 'Scanna en QR eller NFC tag från ',

  // Claim
  claimSerial: 'Länka QRL till ett nytt Serienummer',


  // Checkin
  onComputer: 'Du verkar använda en dator. Den kan ha inkorrekt plats. ',
  sharePosition: 'Vill du skicka din plats till ', 
  allow: 'Tillåt',
  deny: 'Neka',
  unclaimed: 'Ledig QRL',
  unclaimedInfo: 'Logga in för att registrera denna kod som din',
  claimInfo: 'Vill du registrera ett objekt på denna QRL?',
  claim: 'Registrera',

  // Registration
  selectProfile: 'Välj modell',

  // QRL
  QRCodeFor: 'QR-kod för',
  failedQRpt1: 'Misslyckades för ',
  failedQRpt2: ' - är den registrerad?',

  // Users
  user: 'Användare',
  usersForClient: 'Användare for ',
  access: 'Nycklar',
  failedDeleteUser: 'Kunde inte radera denna användare - är det du själv?',
  usernameHint: 'Gärna en emailaddress',
  passwordHint: 'Minst fem bokstäver',
  accessHint: 'A:Administrera, R:Registrera, L:Lista, U:Ladda upp bilder, x: Ditt val',
  deleteUser: 'Radera användare',
  changedOwnPassword: 'Du har bytt ditt eget lösenord. Logga in med ditt nya lösenord.',


  // Profiles
  profile: 'Modell',
  profilesFor: 'Modeller för',
  name: 'Namn',
  note: 'Anteckning',
  nameHint: 'Ett unikt namn, kan inte ändras efteråt',
  profileNoteHint: 'Dina anteckningar om modellen',
  deleteProfileNotice: 'En raderad modell finns kvar dold i systemet för att undvika att tidigare koder blir förstörda. Det går inte att radera alla modeller.',
  editProfileValues: 'Ändra värden',
  values: 'Värden',
  valuesHint: 'Egenskaper för denna modell',
  useSupplierNode: 'Utökar existerande IoT-noder',
  useSupplierNodeHint: 'Om satt till true, länkas och uppdateras data som redan finns hos din dataleverantör',
  requestPublicPosition: 'Be om okända användares position',
  requestPublicPositionHint: 'In satt till true, omber vi okända användare om position',
  helpRequestPublicPosition: `Använd denna funktion med försiktighet. Din organisation kan vara 
  ansvarig för data som samlas in och kan härledas till specifika individer enligt gällande 
  lagstiftning (exempelvis GDPR).`,

  // Supplier Node Selector
  supplierNode: 'IoT Device',
  supplierNodeHint: 'Namn eller en del av namn, tryck Välj för att välja',
  supplierNodeSelect: 'Välj',
  
  // Values
  value: 'Värde',
  valuesFor: 'Värden for',
  attribute: 'Namn',
  label: 'Etikett',
  labelHint: 'Vilken etikett har detta värde för besökare',
  defaultValue: 'Standardvärde',
  defaultHint: 'Standardvärde vid registrering av serienummer',
  public: 'Publikt',
  publicHint: 'Om satt till "true" syns detta för alla',
  editable: 'Kan ändras',
  editableHint: 'Om satt till "true" kan det ändras vid registrering',

  // Data
  dataFor: 'Mallar för',
  willBeReplacedByThis: 'ersätts med detta värde',

  // Access Log
  accessFor: "GDPR Logg för ",

  // Orders
  newOrder: 'Ny beställning',
  ordersFor: 'Beställningar från ',
  credits: 'Krediter',
  quantity: 'Antal',
  unitPrice: 'Enhetspris',
  total: 'Summa',
  totalExVat: 'Summa (ex.moms)',
  exVat: 'Delsumma',
  vat: 'Moms 25%',
  orderNoteHint: 'Denna anteckning bifogas med din faktura',
  confirmOrder: 'Bekräfta beställning',
  date: 'Datum',
  state: 'Status',
  vatRegisterred: 'Rutio AB är registrerat för moms: SE559222341501',

  // Supplier
  status: 'Serverstatus', 
  warning: 'Information',
  supplierChangeWarning: 'Den nya informationen måste referera till ett konto som har den data som du hade innan, annars kan dina QR och NFC sluta fungera.',

  // Listing
  selectRange: 'Välj serienummer',
  range: 'Intervall',
  first: 'Första',
  firstHint: 'Första serienummer i intervallet',
  last: 'Sista',
  lastHint: 'Sista serienummer i intervallet',
  selectARangeOf: 'Välj',
  lastEvent: 'Senaste händelse',
  serialShort: 'SerNr',

  // Printing several
  layout: 'Formattering',

  // Register several
  check: 'Kontrollera',
  created: 'Skapade',
  creditsLeft: 'Återstående krediter',

  // Image View
  noImage: 'Ingen bild',
  upload: 'Ladda upp',
  clear: 'Rensa',

  // General buttons
  cancel: 'Avbryt',
  back: 'Backa',
  next: 'Nästa',
  ok: 'Ok',
  done: 'Klar',
  select: 'Välj',
  edit: 'Ändra',
  add: 'Lägg till',
  submit: 'Spara',
  confirm: 'Beställ',
  delete: 'Ta bort',
  users: 'Användare',
  orders: 'Beställ',
  profiles: 'Modeller',
  data: 'Mallar',
  onedata: 'Mall',
  copyURL: 'Kopiera URL',
  show: 'Visa',
  hide: 'Dölj',
  actions: 'Åtgärd',
  compact: 'Compact',
  log: 'Logg',
  logFor: 'Senaste logg för',
  copyAsCSV: 'Kopiera som CSV',
  logout: 'Logga ut',
  list: 'Lista Intervall',
  matching: 'Matchande',
  latest: 'Senaste',
  map: 'Karta',
  go: 'Öppna',

  // Terms and Conditions
  byLoggingInYouAgree: 'Genom att logga in accepterar du våra',
  termsIntroduction: `Den här tjänsten är avsedd för organisationer som vill hålla reda på sina objekt.
  Vi spårar ingen och ingenting om du inte har valt att bli kund hos oss. 
  Läs mer i vår privacy policy och i våra tjänstevillkor (på Engelska):`,

  // General statements
  preparing: 'Förbereder...',
  processing: 'Bearbetar...',
  positioning: "Platsbestämmer",
  registering: "Registrerar",
  redirecting: "Skickar dig vidare...",
  submitting: "Sparar",
  loading: "Läser in...",
  thankyou: "Tack för din beställning.",
  updated: "Datat har uppdaterats",
  cancelled: "Funktionen har avbrutits",

  // Menus
  administratorMenu: 'Administrera',
  registratorMenu: 'Registrera',
  printSeveral: 'Printa QR-koder',
  registerSeveral: 'Serieproducera',
  accessLog: 'GDPR Access Logg',

  noteAboutApi: 'Gör egen design med Qrlio API',

  // Error messages
  error: 'Meddelande',
  errorNoProfileSelected: 'Ingen modell vald',
  errorMissingInformation: 'Information saknas',
  errorConnectionFailed: 'Tillfälligt problem med anslutningen',
  errorFailedRegister: 'Kunde inte registrera serienummer, finns det redan?',
  errorAccountCreateFailed: 'Kunde inte registera organisationen',
  errorAccountCreateFailed2: 'Den kan vara registrerad redan eller så avvisades någon information.',
  errorAccountCreateFailed3: 'Vänligen kontakta oss på info@qrlio.com om problemet kvarstår.',
  errorCouldNotDeleteProfile: 'Modellen kunde inte tas bort',
  errorSubmitFailed: 'Det gick inte att skicka in nya värden just nu',
  errorCannotRegister: 'Du verkar inte ha nyckeln R som krävs för att registrera enheter',

  // Help
  help : "Vad är detta?",
  helpSerial: `Ett Serienummer är ett nummer du själv bestämmer för att unikt representera ett av dina objekt.
     Om du har tilldelats nyckeln R kan du registrera ett nytt serienummer här.
     Du kan med ett existerande serienummer mata in det här och få en QR-kod eller URL för att använda i en NFC-tagg.`,
  helpProfiles: `En modell representerar typen av objekt din organisation spårar med denna tjänst.
    Exempel på modell är t.ex Planta om man driver plantskola, Cykel om du hyr ut cyklar, Paket om du transporterar paket.
    Modellen har ett namn och sedan en lista med värden som varje objekt har. Om objektet t.ex. är en planta så kan den förutom 
    sitt serienummer också ha en art och ett planteringsdatum som värden. Du väljer själv. Tips: Gör dessa så generella som möjligt,
    dvs om du har plantor så gör en modell Planta, och lägg till ett värde 'art' där du benämner arten för plantan. På så vis kan
    du använda ett separat system för att hantera dina inventarier och inte kopiera dess innehåll hit.`,
  helpDatas: `Mallar är din organisations länkar och texter samlade på ett ställe. 
    Istället för att varje objekt ska ha kopior av dessa så kan du enkelt referera till mallarna ifrån objektens värden.
    Om du till exempel har plantskola och vill att din kund ska kunna beställa en likadan planta till när hen scannar din QR-kod,
    så kan du ha en mall med namn orderpage och värdet https://minplantskola.se/order?serial={value.serial}. 
    I din modell Planta kan du lägga in ett värde med namnet order och värdet {orderpage}. I efterhand kan du då ändra bara i 
    Mallen om du senare byter ordersida.`,
  helpData: `En mall har ett namn som får innehålla bokstäverna a-z samt bindestreck (-).
    Värde kan vara vilken text som helst. Om texten innehåller {a} så ersätts den delen med värdet 
    med namn a från objektet eller från en annan mall a.`,
  helpValue: `Varje värde har ett namn, en Etikett, ett Standardvärde, Nycklar och information om det kan ändras och om det är publikt.
    Namnet används för att säkerställa att datat inte sammanblandas med annat data. Etiketten visas för användare av systemet.
    Nycklarna används för att bestämma vem som kan se detta värde, men om det är publikt betyder det att alla som läser av QR-koden kan se värdet.
    Standardvärdet är vad som registreras när ett nytt serienummer registreras med den modell värdet ingår i. Om det kan ändras så kommer den 
    som skapar serienumret omfrågas om vilket värde det ska ha för just det nya serienumret.`,
  helpDeft: `Värdet kan representera en länk, en inmatning eller ett värde. Om texten börjar med http, tel: eller mailto: kommer den hanteras 
    som en länk av systemet och visas som en knapp. Om texten istället börjar med edit:n så kommer systemet visa en textinmatning 
    som uppdaterar objektets värde n. I alla övriga fall kommer systemet visa detta som en text.
    Du kan referera till andra värden med {x} och det kommer ersättas med värdet på en malls värde x eller objektets värde x.
    Vi rekommenderar att i så stor grad som möjligt referera till mallar för alla värden som inte är unika för objektet.`,
  helpUseSupplierNode: `Om detta har värdet true så kommer alla serienummer som skapas med denna modell ha sitt data i existerande IoT-noder,
  du väljer vilken när du skapar ett nytt serienummer.
  Detta är ett mycket kraftfullt sätt att utöka funktionen hos dessa IoT-noder, t.ex med positionering eller med andra funktioner.
  Var nogrann med att inte ha värden med samma namn som redan existerande värden i din IoT-nod, de kommer då att skrivas över.`,
  helpAPI: `Besök https://github.com/Rutio-se/qrlio-scripting för att skapa egna script för att anpassa Qrlio.com till din verksamhets behov.`,
  }
