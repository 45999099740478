import React from 'react';
import { buttonStyle, pageStyle } from "../styles";
import { Input, invalid } from "../components/Input";
import { Button } from "../components/Button";
import { TermsAndConditions } from '../components/TermsAndConditions';
import { api } from "../App";
import { i18 } from '../i18n/i18';


export const NewClient = (props) => {
  const [name, setName] = React.useState("");
  const [adapter, setAdapter] = React.useState("local");
  const [supplier, setSupplier] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [billingOrgId, setBillingOrgId] = React.useState("");
  const [website, setWebsite] = React.useState("https://www.");
  const [billingEmail, setBillingEmail] = React.useState("");
  const [billingReference, setBillingReference] = React.useState("");
  const [currency, setCurrency] = React.useState("SEK");
  const [contactPhone, setContactPhone] = React.useState("+46");
  const [contactEmail, setContactEmail] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("SE");
  const [adminPassword, setAdminPassword] = React.useState("");
  const [adminPassword2, setAdminPassword2] = React.useState("");
  const [objectName, setObjectName] = React.useState("");
  const [state, setState] = React.useState(-1);
  
  // Checking inputs whenever state changes
  const check = () => {
    if (objectName.length === 0)
      return {trouble: "Please select what type of object you want to track", st: -1}
    if (name.length === 0)
      return {trouble: "Please enter company/organization name", st:0};
    if (countryCode.length !== 2)
      return {trouble: "Please check country code", st:0};
    if (!contactEmail.includes('@'))
      return {trouble: "Please check contact email", st:1};
    if (contactPhone.length > 0 && (countryCode !== "SE" && !contactPhone.startsWith('+')))
      return {trouble: "Please include + international prefix on contact phone", st:1};
    if ((!website.startsWith("http")) || website.length < 10)
      return {trouble: "Please provide a Website", st:1};
    if (billingOrgId.length < 10 && countryCode==="SE")
      return {trouble: "Please add a Swedish Organization Code (organisationsnummer)", st:2};
    if (!billingEmail.includes('@'))
      return {trouble: "Please check billing email", st:2};
    if (billingReference.length===0)
      return {trouble: "Please include a billing reference", st:2};
    if (adapter === "yggio3" && (supplier.length < 20 || username.length === 0 || password.length === 0))
      return {trouble: "Please supply account information for Yggio", st:3};
    if (adapter === "odoo" && (supplier.length < 20 || supplier.split(";").length!==3 || !supplier.includes('https://')))
      return {trouble: "Please supply account information for ODOO in Server field - Format: <database>;<stock.lot>;https://<server url>", st:3};
    if (adapter === "odoo" && (username.length === 0 || password.length === 0))
      return {trouble: "Please supply account information for ODOO - Use API key as password", st:3};
    if (adminPassword !== adminPassword2)
      return {trouble: "The passwords differ, please try again", st:4};
    if (adminPassword.length <= 5)
      return {trouble: "Please add an admin password of length exceeding 5", st:4};
    if (adminPassword === contactEmail)
      return {trouble: "Admin password equals account name", st:4};
    return {trouble: null, st:0};
  }

  React.useEffect(()=> {
    if (state !== 20)
      return;
    let cancelled = false;
    const f = async () => {
      try {
        const call = api + '/newclient' +
          '?name=' + encodeURIComponent(name) +
          '&countryCode=' + encodeURIComponent(countryCode) +
          '&contactEmail=' + encodeURIComponent(contactEmail) +
          '&contactPhone=' + encodeURIComponent(contactPhone) +
          '&website=' + encodeURIComponent(website) +
          '&billingOrgId=' + encodeURIComponent(billingOrgId) +
          '&billingEmail=' + encodeURIComponent(billingEmail) +
          '&billingReference=' + encodeURIComponent(billingReference) +
          '&currency=' + encodeURIComponent(currency) +
          '&adapter=' + encodeURIComponent(adapter) +
          '&supplier=' + encodeURIComponent(supplier) +
          '&username=' + encodeURIComponent(username) +
          '&password=' + encodeURIComponent(password) +
          '&adminPassword=' + encodeURIComponent(adminPassword) + 
          '&objectName=' + encodeURIComponent(objectName);

        const response = await fetch(call);
        const ok = await response.json();
        if (cancelled)
          return;
        setState(ok ? 22 : 23);
      } catch (err) {
        if (cancelled)
          return;
        setState(23);
      }
    }
    f();
    return ()=>{cancelled=true};
  }, [state]);

  if (state === -1) {
    return <div style={pageStyle}>
      {i18('whatKind')}
      <hr/>
      <Input key={1} type="name" placeholder={i18('nameYourThing')} hint={i18('thingHint')} onChange={e=>setObjectName(e.target.value)} value={objectName} />
      <hr/>
        <Button text={i18('cancel')} onClick={()=>props.onDone("default")} />
        <Button disabled={invalid('name', objectName)} text={i18('next')} onClick={()=>setState(0)} />
    </div>
  } else if (state === 0) {
    return <div style={pageStyle}>{i18('registerAccount')} - {i18('generalInformation')}
      <hr/>
        <Input key={2} type="name" placeholder={i18('organization')} hint={i18('organizationNameHint')} value={name} onChange={(e)=>setName(e.target.value)}/>
        <Input key={3} type="countrycode" placeholder={i18('countryCode')} hint={i18('countryCodeHint')} maxLength="2" value={countryCode} onChange={(e)=>setCountryCode(e.target.value)}/>
      <hr/>
        <Button text={i18('back')} onClick={()=>setState(-1)} />
        <Button disabled={invalid('name', name)||invalid('countrycode', countryCode)} text={i18('next')} onClick={()=>setState(1)} />
      </div>;
  }
  else if (state === 1) {
    return <div style={pageStyle}>{i18('registerAccount')} - {i18('contactInformation')}
      <hr/>
        <Input key={4} type="email" placeholder={i18('contactEmail')} hint={i18('contactEmailHint')} value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)}/>
        <Input key={5} placeholder={i18('contactPhone')} hint={i18('contactPhoneHint')} value={contactPhone} onChange={(e)=>setContactPhone(e.target.value)}/>
        <Input key={6} type="url" placeholder={i18('website')} hint={i18('websiteHint')} value={website} onChange={e=>setWebsite(e.target.value)} />
      <hr/>
        <Button text={i18('back')} onClick={()=>setState(0)} />
        <Button disabled={invalid('email', contactEmail)||invalid('url', website)} text={i18('next')} onClick={()=>setState(2)} />
    </div>
  }
  else if (state === 2) {
    return <div style={pageStyle}>{i18('registerAccount')} - {i18('billingInformation')}
      <hr/>
        <Input key={7} type="orgcode" placeholder={i18('billingOrgId')} hint={i18('billingOrgIdHint')} value={billingOrgId} onChange={(e)=>setBillingOrgId(e.target.value)}/>
        <Input key={8} type="email" placeholder={i18('billingEmail')} hint={i18('billingEmailHint')}  value={billingEmail} onChange={(e)=>setBillingEmail(e.target.value)}/>
        <Input key={9} type="currency" placeholder={i18('preferredCurrency')} hint={i18('preferredCurrencyHint')}  value={currency} onChange={(e)=>setCurrency(e.target.value)}/>
        <Input key={10} type="billingref" placeholder={i18('billingReference')} hint={i18('billingReferenceHint')}  value={billingReference} onChange={(e)=>setBillingReference(e.target.value)}/>
      <hr/>
        <Button text={i18('back')} onClick={()=>setState(1)} />
        <Button disabled={invalid('email', billingEmail) || invalid('billingref', billingReference) || invalid('currency', currency)} text={i18('next')} onClick={()=>setState(3)} />
    </div>
  }
  else if (state === 3) {
    return <div style={pageStyle}>{i18('registerAccount')} - {i18('storageOptions')}
      <hr/>
        {i18('supplier')}<br/>
        <select style={buttonStyle} value={adapter} onChange={e => setAdapter(e.target.value)}>
          <option key="1" value="yggio3">{i18('yggio3')}</option>
          <option key="3" value="odoo">{i18('odoo')}</option>
          <option key="2" value="local">{i18('qrlio')}</option>
          <option key="0" value="custom">{i18('custom')}</option>
        </select>
        {(adapter !== "local") && <div>
          {adapter==="custom" && <div><a href="mailto:info@qrlio.com">info@qrlio.com</a></div>}
          <hr></hr>
          <Input key={11} placeholder={i18('server')} hint={adapter + " " +i18('server')} value={supplier} onChange={(e)=>setSupplier(e.target.value)}/>
          <Input key={12} placeholder={i18('username')} hint={adapter + " " +i18('username')} value={username} onChange={(e)=>setUsername(e.target.value)}/>
          <Input key={13} placeholder={i18('password')} hint={adapter + " " +i18('password')} value={password} onChange={(e)=>setPassword(e.target.value)}/>
          </div>
        }

      <hr/>
        <Button text={i18('back')} onClick={()=>setState(2)} />
        <Button text={i18('next')} onClick={()=>setState(4)} />
    </div>;
  } else if (state === 4) {
    return <div style={pageStyle}>{i18('registerAccount')} - {i18('adminInformation')}
      <hr/>
      <Input key={14} placeholder={i18('username')} hint={i18('usingContactEmail')} disabled="true" value={contactEmail}></Input>
      <Input key={15} type="password" placeholder={i18('password')} hint={i18('createPasswordHint')} value={adminPassword} onChange={e=>setAdminPassword(e.target.value)}></Input>
      <Input key={16} type="password" placeholder={i18('confirmPassword')} hint={i18('confirmPasswordHint')} value={adminPassword2} onChange={e=>setAdminPassword2(e.target.value)}></Input>
      <hr/>
        <Button text={i18('back')} onClick={()=>setState(3)} />
        <Button disabled={invalid('password', adminPassword) || (adminPassword !== adminPassword2)} text={i18('submit')} onClick={()=>setState(10)} />
    </div>;
  }
  else if (state === 10) {
    const {trouble, st} = check();
    if (trouble)
      return <div style={pageStyle}>{trouble}
        <hr/>
          <Button text={i18('back')} onClick={()=>setState(st)}/>
      </div>
    else 
      return <div style={pageStyle}>{i18('acceptTermsAndConditions')}<hr/>
        <TermsAndConditions></TermsAndConditions>
        <hr></hr>
        <Button text={i18('back')} onClick={()=>setState(4)}/>
        <Button text={i18('ok')} onClick={()=>setState(20)}/>
      </div>
  }
  else if (state === 20) {
    return <div style={pageStyle}>{i18('preparing')}</div>;
  }
  else if (state === 21) {
    return <div style={pageStyle}>{i18('processing')}</div>
  }
  else if (state === 22) {
    return <div style={pageStyle}>{i18('accountCreated')}<hr></hr>
      <Button onClick={()=>props.onDone()} text={i18('done')}></Button></div>
  }
  else if (state === 23) {
    return <div style={pageStyle}>{i18('errorAccountCreateFailed')}<hr></hr>
    {i18('errorAccountCreateFailed2')}<br/>
    {i18('errorAccountCreateFailed3')}<hr></hr>
      <Button onClick={()=>setState(0)} text={i18('back')}></Button></div>
  }
  else
    return <div style={pageStyle}>Interal error - unknown state {state}</div>
}
